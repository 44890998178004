import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import MobileWorkoutStepTitle from './MobileWorkoutStepTitle';
import MobileWorkoutStepDurationType from './MobileWorkoutStepDurationType';
import MobileWorkoutStepSetDuration from './MobileWorkoutStepSetDuration';
import MobileWorkoutStepDivider from './MobileWorkoutStepDivider';

const useStyles = makeStyles(theme => ({
  root: { marginTop: 15 },
  cardBody: {
    padding: 0
  },
  list: {
    width: '100%',
    padding: 0
  }
}));

export default function MobileWorkoutCoolDownStepCard(props) {
  const { user, step, removeStep, type } = props;
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <MobileWorkoutStepTitle
        user={user}
        title={'COOL DOWN'}
        removeStep={removeStep}
        step={step}
        type={type}
      />
      <CardContent className={classes.cardBody}>
        <List className={classes.list}>
          <MobileWorkoutStepDurationType durationType={step.durationType} />
          <MobileWorkoutStepDivider />
          <MobileWorkoutStepSetDuration
            durationValue={step.durationValue}
            durationValueType={step.durationValueType}
          />
        </List>
      </CardContent>
    </Card>
  );
}
