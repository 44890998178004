import {
  Box,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import RepeatIcon from '@material-ui/icons/Repeat';
import TrendingDownOutlinedIcon from '@material-ui/icons/TrendingDownOutlined';
import TrendingUpOutlinedIcon from '@material-ui/icons/TrendingUpOutlined';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

import RepeatStep from './RepeatStep';
import WeekCalendar from './WeekCalendar';
import WorkoutStepTemplate from './WorkoutStepTemplate';
import WorkoutTemplateSelection from './WorkoutTemplateSelection';

import getCooldownStepTemplate from './Templates/cooldownStepTemplate';
import getWarmupStepTemplate from './Templates/warmupStepTemplate';
import getNewRepeatStep from './Templates/workoutRepeatStepTemplate';
import getNewWorkoutStep from './Templates/workoutStepTemplate';
import getWorkoutTemplate from './Templates/workoutTemplate';
import WeeklyPlanPreviewModal from './WeeklyPlanPreviewModal/WeeklyPlanPreviewModal';

import { SnackbarNotification } from 'components';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import { SubGroup } from 'components';
import workoutTemplate from './Templates/workoutTemplate';
import validateWorkout from './ValidateWorkout';
import { startOfWeek } from 'date-fns';
import MobileWorkoutTemplateSelection from './MobileWorkoutTemplateSelection';

const useStyles = makeStyles(theme => ({
  toolbar: theme.mixins.toolbar,
  content: {
    textAlign: 'start',
    flexGrow: 1,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up(1150)]: {
      paddingLeft: theme.spacing(10),
      paddingRight: theme.spacing(10)
    }
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  subTitle: {
    fontWeight: 'bold',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main
  },
  button: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    // backgroundColor: '#A4A4A4',
    color: theme.palette.white,
    boxShadow: 'none',
    textTransform: 'none',

    [theme.breakpoints.up('sm')]: {
      width: 'fit-content',
      minWidth: '17rem'
    },

    '&:hover': {
      // backgroundColor: '#a4a4a4a3'
      backgroundColor: theme.palette.secondary.main
    }
  },
  fit_button: {
    flex: '1',

    [theme.breakpoints.up('sm')]: {
      width: 'fit-content',
      minWidth: 'unset'
    }
  },
  btn_margin: {
    marginRight: theme.spacing(2)
  },
  btn_padding: {
    padding: '0 2rem',
    [theme.breakpoints.up('sm')]: {
      padding: '0'
    }
  },
  iconButton: {
    marginRight: '8px',
    [theme.breakpoints.down(1000)]: {
      display: 'none'
    }
  },
  textField: {
    width: '100%',
    maxWidth: 'unset',
    marginTop: theme.spacing(2)

    // [theme.breakpoints.up('sm')]: {
    //   maxWidth: '18.91rem',
    // },
  },
  buttons: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    maxWidth: '750px',
    textWrap: 'nowrap',
    [theme.breakpoints.down(600)]: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: '10px'
    }
  },
  cancel_save_buttons: {
    display: 'flex',

    [theme.breakpoints.up('sm')]: {
      maxWidth: '18.9rem'
    }
  },
  box_title: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
    marginBottom: '1rem',
    color: theme.palette.primary.main
  },
  textArea: {
    borderRadius: '4px',
    width: '100%',
    backgroundColor: 'transparent',
    color: 'rgba(0, 0, 0, 0.87)',
    cursor: 'text',
    display: 'inline-flex',
    position: 'relative',
    fontSize: '1rem',
    boxSizing: 'border-box',
    alignItems: 'center',
    fontFamily: 'inherit',
    fontWeight: '400',
    lineHeight: '1.1876em',
    letterSpacing: '0.00938em',
    padding: '10.5px 14px'
  },
  mTop3: {
    marginTop: theme.spacing(3)
  },
  btn_remove: {
    backgroundColor: '#ffffff',
    color: 'black',
    border: '1px solid #000000',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: 'white',
      borderColor: 'white'
    }
  },
  priamryTextColor: {
    color: theme.palette.primary.main
  },
  secondaryTextColor: {
    color: theme.palette.secondary.main
  }
}));

const getThisWeekStartDate = () => {
  const today = new Date();
  const dayOfWeek = today.getUTCDay();
  const startDateOfWeek = new Date(
    today.getTime() - dayOfWeek * 24 * 60 * 60 * 1000
  );
  return startDateOfWeek;
};

const getNextWeeksSundayDate = () => {
  const today = new Date();
  const dayOfWeek = today.getUTCDay();
  const startDateOfWeek = new Date(
    today.getTime() + (7 - dayOfWeek) * 24 * 60 * 60 * 1000
  );
  return startDateOfWeek;
};

const isSameDate = (date1, date2) => {
  const startOfDate1 = new Date(date1).setHours(0, 0, 0, 0);
  const endOfDate1 = new Date(date1).setHours(23, 59, 59, 999);
  const dateTwo = new Date(date2).getTime();
  return dateTwo >= startOfDate1 && dateTwo <= endOfDate1;
};

const getWorkoutTemplateOfDate = (weeklyWorkoutTemplatesPlan, dateObject) => {
  if (weeklyWorkoutTemplatesPlan.length == 0) return null;
  const workoutTemplate = weeklyWorkoutTemplatesPlan.filter(workout =>
    isSameDate(workout.dateObject, dateObject)
  );
  if (workoutTemplate.length == 0) return null;

  return workoutTemplate[0].workoutTemplate; // workoutTemplatesWithoutSameSubGroup;
};

const formatWeeklyWorkoutTempplatesPlan = (
  startWeekDate,
  weeklyWorkoutTemplatesPlan,
  subGroup
) => {
  let weeklyWorkoutTemplates = [];

  for (let i = 0; i < 7; i++) {
    let date = new Date(startWeekDate.getTime() + i * 24 * 60 * 60 * 1000);
    // let dayOfWeek = date.toLocaleString('default', { weekday: 'long' });
    let day = date.getDate();
    let month = date.getMonth() + 1; // Month is 0-based
    let dayNumber = date.getDay();
    let days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ];
    let actualDayOfWeek = days[dayNumber];
    let workoutTemplate = getWorkoutTemplateOfDate(
      weeklyWorkoutTemplatesPlan,
      date
    );
    let formattedDate = {
      dateObject: date,
      dayOfWeek: `${actualDayOfWeek}`,
      date: `${day}/${month}`,
      workoutTemplate,
      isActive: false,
      isWorkout: workoutTemplate ? true : false,
      subGroup
    };

    weeklyWorkoutTemplates[i] = formattedDate;
  }
  // set the first day of the week as active by default
  // it changes once the user clicks on a different date
  weeklyWorkoutTemplates[0].isActive = true;

  return weeklyWorkoutTemplates;
};

const getPreviousWeekStartDate = date => {
  return new Date(date.getTime() - 7 * 24 * 60 * 60 * 1000);
};

const getNextWeekStartDate = date => {
  return new Date(date.getTime() + 7 * 24 * 60 * 60 * 1000);
};

const getWeeklyWorkoutsPlanByStartDate = async (
  userEmail,
  startWeekDate,
  selectedSubgroup
) => {
  const weeklyWorkoutsPlanTemplateRaw = await axios.post(
    `${process.env.REACT_APP_SERVER_URL}/workout/workoutsPlan`,
    {
      userEmail,
      startWeekDate
    }
  );
  const workoutsPlanTemplatesOfTheWeek = weeklyWorkoutsPlanTemplateRaw.data.workoutPlanTemplates.filter(
    item => item.subGroup === selectedSubgroup
  );

  const weeklyWorkoutTemplatesPlan = formatWeeklyWorkoutTempplatesPlan(
    startWeekDate,
    workoutsPlanTemplatesOfTheWeek,
    selectedSubgroup
  );

  return weeklyWorkoutTemplatesPlan;
};

const getPlannedWorkoutTemplateByUser = async userEmail => {
  const PlannedWorkoutTemplateRaw = await axios.post(
    `${process.env.REACT_APP_SERVER_URL}/workout/getWorkoutTemplate`,
    { userEmail }
  );

  const PlannedWorkoutTemplateData =
    PlannedWorkoutTemplateRaw.data.workoutPlanTemplates;

  return PlannedWorkoutTemplateData;
};

/**
 * Renders the CreateWorkout component.
 * This component has 2 Modes
 *
 *
 * ### **NECESSARY PROPS**
 * @param {Object} props.user - The user object.
 *
 *
 * ## **1. Normal Mode**
 * Only Needs the user prop
 * allows the user to create workout plans
 *
 * **For the monthly view only**
 * @param {Object} props.workoutSelectedByMonthView - The workout selected by month view object.
 *
 * ## **2. Template Mode**
 * This mode makes the create workout component only make templates
 *
 * the template mode needs all these props
 *
 * @param {boolean} props.isTemplateSection - Indicates if it is on template mode.
 * @param {Object} props.template - The template object.
 * @param {function} props.onTemplateSave - Callback of template save.
 * @param {function} props.onTemplateDelete - Callback of template deletion.
 */
function CreateWorkout({
  user,
  workoutSelectedByMonthView,
  isTemplateSection,
  onTemplateDelete,
  template,
  onTemplateSave
}) {
  const classes = useStyles();
  const [isCopyModalOpen, setIsCopyModalOpen] = useState(false);
  const [subGroupsForCopy, setSubGroupsForCopy] = useState(['General']);
  const [subgroupToCopyTo, setSubgroupToCopyTo] = useState('General');
  const [subgroups, setSubgroups] = useState(['General']);
  const [loading, setLoading] = useState(false);
  const [workoutName, setWorkoutName] = useState('');
  const [steps, setSteps] = useState([
    getWarmupStepTemplate(),
    getNewWorkoutStep('INTERVAL'),
    getNewRepeatStep(),
    getCooldownStepTemplate()
  ]);
  const [workoutNotes, setWorkoutNotes] = useState('');
  const [startWeekDate, setStartWeekDate] = useState(getNextWeeksSundayDate());
  const [weeklyWorkoutsPlan, setWeeklyWorkoutsPlan] = useState([]);
  const [monthViewSelectionSet, setMonthViewSelectionSet] = useState(false);
  const [workoutsPlanTemplate, setWorkoutsPlanTemplate] = useState([]);
  const [activeDateObject, setActiveDateObject] = useState(startWeekDate);

  const [showCopyTemplate, setShowCopyTemplate] = useState(true);

  const [
    isDeleteWorkoutPlanModalOpen,
    setIsDeleteWorkoutPlanModalOpen
  ] = useState(false);

  const [isNotification, setIsNotification] = useState(false);
  const [notificationSeverity, setNotificationSeverity] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');

  const displayNotification = (severity, message) => {
    setNotificationSeverity(severity);
    setNotificationMessage(message);
    setIsNotification(true);
  };
  const isMobile = !useMediaQuery('(min-width:900px)');
  // Handling Subgroups
  const [selectedSubgroup, setSelectedSubgroup] = useState(
    workoutSelectedByMonthView ? workoutSelectedByMonthView.subGroup : 'General'
  );

  useEffect(() => {
    clearAllInputFields();
    const monthViewChange =
      workoutSelectedByMonthView && !monthViewSelectionSet;
    if (monthViewChange) setLoading(true);
    getWeeklyWorkoutsPlanByStartDate(
      user.email,
      monthViewChange
        ? startOfWeek(new Date(workoutSelectedByMonthView.dateObject), {
            weekStartsOn: 0
          })
        : startWeekDate,
      selectedSubgroup
    )
      .then(weeklyWorkoutTemplates => {
        setWeeklyWorkoutsPlan(weeklyWorkoutTemplates);
        return weeklyWorkoutTemplates;
      })
      .then(weeklyWorkoutTemplates => {
        if (weeklyWorkoutTemplates[0].workoutTemplate)
          displayWorkoutTemplate(weeklyWorkoutTemplates[0].workoutTemplate);
        if (monthViewChange) {
          setMonthViewSelectionSet(true);
          setLoading(false);
          changeDate(workoutSelectedByMonthView, weeklyWorkoutTemplates);
        }
      });

    getPlannedWorkoutTemplateByUser(user.email).then(workoutTemplatesOfUser => {
      setWorkoutsPlanTemplate(workoutTemplatesOfUser);
      return workoutTemplatesOfUser;
    });
  }, [startWeekDate, selectedSubgroup]);

  const addWarmUp = () => {
    const lastWarmUpStepIndex = steps.findLastIndex(
      step => step.intensity === 'WARMUP'
    );
    if (steps.length === 0) {
      setSteps([...steps, getWarmupStepTemplate()]);
    } else if (lastWarmUpStepIndex >= 0) {
      setSteps([
        ...steps.slice(0, lastWarmUpStepIndex + 1),
        getWarmupStepTemplate(),
        ...steps.slice(lastWarmUpStepIndex + 1)
      ]);
    } else {
      setSteps([getWarmupStepTemplate(), ...steps]);
    }
  };
  const addWorkoutStep = () => {
    const lastCooldownStepIndex = steps.findIndex(
      step => step.intensity === 'COOLDOWN'
    );
    if (lastCooldownStepIndex >= 0) {
      setSteps([
        ...steps.slice(0, lastCooldownStepIndex),
        getNewWorkoutStep('INTERVAL'),
        ...steps.slice(lastCooldownStepIndex)
      ]);
    } else {
      setSteps([...steps, getNewWorkoutStep('INTERVAL')]);
    }
  };

  const addRepeatStep = () => {
    const lastCooldownStepIndex = steps.findIndex(
      step => step.intensity === 'COOLDOWN'
    );
    if (lastCooldownStepIndex >= 0) {
      setSteps([
        ...steps.slice(0, lastCooldownStepIndex),
        getNewRepeatStep(),
        ...steps.slice(lastCooldownStepIndex)
      ]);
    } else {
      setSteps([...steps, getNewRepeatStep()]);
    }
  };

  const addCoolDown = () => {
    setSteps([...steps, getCooldownStepTemplate()]);
  };

  const handleWorkoutNameChange = event => setWorkoutName(event.target.value);
  const handleWorkoutNotesChange = event => setWorkoutNotes(event.target.value);

  const removeStep = step => {
    const revisedSteps = steps.filter(currentStep => currentStep != step);
    setSteps(revisedSteps);
  };

  const prepareWorkoutTemplate = subgroup => {
    const workoutTemplate = getWorkoutTemplate();
    workoutTemplate.userEmail = user.email;
    workoutTemplate.workoutName = workoutName;
    workoutTemplate.steps = steps;
    workoutTemplate.workoutNotes = workoutNotes;
    workoutTemplate.subGroup = subgroup
      ? subgroup
      : isTemplateSection
      ? template.subGroup
      : selectedSubgroup;
    return workoutTemplate;
  };

  const handleDeleteWorkoutTemplate = async () => {
    const workoutTemplate = prepareWorkoutTemplate();
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/workout/removeTemplateFromDB`,
      { user: user, template: workoutTemplate }
    );
    displayNotification(response.data.severity, response.data.msg);
    onTemplateDelete();
  };

  const SaveWorkoutTemplateToSubgroup = async subgroup => {
    const workoutTemplate = prepareWorkoutTemplate(subgroup);

    const workoutTemplateValidationResponse = validateWorkout(workoutTemplate);
    if (!workoutTemplateValidationResponse.isValidWorkout) {
      displayNotification(
        workoutTemplateValidationResponse.notificationSeverity,
        workoutTemplateValidationResponse.notificationMessage
      );
      return;
    }

    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/workout/template`,
      workoutTemplate
    );
    if (response.data.severity == 'error')
      displayNotification(response.data.severity, response.data.msg);
    else
      displayNotification(
        'success',
        `the template was copied to the ${subgroup} subgroup`
      );
  };

  const handleSaveWorkoutTemplate = async () => {
    const workoutTemplate = prepareWorkoutTemplate();

    const workoutTemplateValidationResponse = validateWorkout(workoutTemplate);
    if (!workoutTemplateValidationResponse.isValidWorkout) {
      displayNotification(
        workoutTemplateValidationResponse.notificationSeverity,
        workoutTemplateValidationResponse.notificationMessage
      );
      return;
    }

    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/workout/template`,
      workoutTemplate
    );

    displayNotification(response.data.severity, response.data.msg);
    if (onTemplateSave) onTemplateSave(workoutTemplate, response.data);
  };

  const handleWorkoutTemplateDisplay = event => {
    const workoutTemplate = isTemplateSection ? event : event.target.value;
    setWorkoutName(workoutTemplate.workoutName);
    setSteps(workoutTemplate.steps);
    setWorkoutNotes(workoutTemplate.workoutNotes);
  };

  const clearAllInputFields = () => {
    setWorkoutName('');
    setSteps([
      getWarmupStepTemplate(),
      getNewWorkoutStep('INTERVAL'),
      getNewRepeatStep(),
      getCooldownStepTemplate()
    ]);
    setWorkoutNotes('');
  };

  const displayWorkoutTemplate = workoutTemplate => {
    setWorkoutName(
      workoutTemplate[0]?.workoutName || workoutTemplate.workoutName
    );
    setSteps(workoutTemplate[0]?.steps || workoutTemplate.steps);
    setWorkoutNotes(
      workoutTemplate[0]?.workoutNotes || workoutTemplate.workoutNotes
    );
  };

  const changeDate = (day, weeklyWorkoutsCached) => {
    clearAllInputFields();
    const selectedWorkouts = weeklyWorkoutsCached
      ? weeklyWorkoutsCached
      : weeklyWorkoutsPlan;
    const newWeeklyWorkoutsPlan = selectedWorkouts.map(item => {
      if (isSameDate(item.dateObject, day.dateObject)) {
        item.isActive = true;
      } else {
        item.isActive = false;
      }
      return item;
    });
    if (day.workoutTemplate) displayWorkoutTemplate(day.workoutTemplate);

    setActiveDateObject(day.dateObject);
    setWeeklyWorkoutsPlan(newWeeklyWorkoutsPlan);
  };

  const handlePreviousWeek = () => {
    const previousWeekStartDate = getPreviousWeekStartDate(startWeekDate);
    setActiveDateObject(previousWeekStartDate);
    setStartWeekDate(previousWeekStartDate);
  };

  const handleNextWeek = () => {
    const nextWeekStartDate = getNextWeekStartDate(startWeekDate);
    setActiveDateObject(nextWeekStartDate);
    setStartWeekDate(nextWeekStartDate);
  };

  const handleSelectedDateFromCalendar = startWeekDate => {
    setLoading(true);
    setActiveDateObject(startWeekDate);
    setStartWeekDate(startWeekDate);

    setTimeout(() => {
      setLoading(false);
    }, 1400);
  };

  const saveWorkoutToPlan = async () => {
    const workoutPlanTemplate = {};
    const workoutTemplate = prepareWorkoutTemplate();

    const workoutValidationResponse = validateWorkout(workoutTemplate);
    if (!workoutValidationResponse.isValidWorkout) {
      displayNotification(
        workoutValidationResponse.notificationSeverity,
        workoutValidationResponse.notificationMessage
      );
      return;
    }

    workoutPlanTemplate.userEmail = user.email;
    workoutPlanTemplate.dateObject = activeDateObject;
    workoutPlanTemplate.workoutTemplate = workoutTemplate;
    workoutPlanTemplate.workoutTemplate.sport = 'RUNNING';
    workoutPlanTemplate.subGroup = selectedSubgroup;

    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/workout/toPlanTemplate`,
      workoutPlanTemplate
    );
    displayNotification(response.data.severity, response.data.msg);
    return response;
  };

  const handleSaveWeeklyWorkoutsPlan = async () => {
    const savedWorkout = await saveWorkoutToPlan();
    if (!savedWorkout) return;

    const newWeeklyWorkoutsPlan = weeklyWorkoutsPlan.map(workout => {
      if (isSameDate(workout.dateObject, activeDateObject)) {
        workout.isWorkout = true;
        workout.workoutTemplate = prepareWorkoutTemplate();
      }
      return workout;
    });
    setWeeklyWorkoutsPlan(newWeeklyWorkoutsPlan);
  };

  const handlePreviewWeeklyWorkoutsPlan = async () => {
    handleModalOpen();
  };

  const removeWorkoutFromPlanTemplate = async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/workout/removeFromPlanTemplate`,
      { userEmail: user.email, activeDateObject },
      { validateStatus: () => true }
    );
    if (response.status == 200) {
      displayNotification('success', 'workout deleted successfully');
    } else if (response.status >= 400 && response.status <= 421) {
      displayNotification(response.data.severity, response.data.msg);
    } else if (response.status >= 500) {
      displayNotification(
        'error',
        'there was an error deleting the workout template'
      );
    } else {
      displayNotification(
        'error',
        'there was an unknown error deleting the workout template'
      );
    }
  };

  const handleRemoveWeeklyWorkoutFromPlan = async () => {
    await removeWorkoutFromPlanTemplate();
    clearAllInputFields();
    const newWeeklyWorkoutsPlan = weeklyWorkoutsPlan.map(workout => {
      if (isSameDate(workout.dateObject, activeDateObject)) {
        workout.isWorkout = false;
        workout.workoutTemplate = null;
      }
      return workout;
    });
    setWeeklyWorkoutsPlan(newWeeklyWorkoutsPlan);
    setIsDeleteWorkoutPlanModalOpen(false);
  };

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const [runnersList, setRunnersList] = React.useState();
  useEffect(() => {
    const getActiveRunnersList = async () => {
      const payload =
        user.role == 'trainer'
          ? { trainerUserId: user._id, isActive: true }
          : { runnerId: user._id, isActive: true };

      const runnersList = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/runners`,
        payload
      );
      const activeRunnersList = runnersList.data.filter(
        runner => runner.isActive && runner.subGroup === selectedSubgroup
      );
      setRunnersList(activeRunnersList);
    };
    getActiveRunnersList();
  }, [selectedSubgroup]);

  useEffect(() => {
    if (isTemplateSection) {
      handleWorkoutTemplateDisplay(template);
      if (template.steps.length === 0) {
        setShowCopyTemplate(false);
      }
    }
  }, []);

  useEffect(() => {
    const fetchSubGroupsForCopy = async () => {
      const payload = user.role == 'trainer' && { trainerUserId: user._id };
      const trainerUserId = payload.trainerUserId;
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/getSubgroups`,
        { trainerUserId }
      );
      // Need to change it and make sure every trainer has at least General subgroup in their subgroups when created
      // and that you cannot delete this subgroup
      setSubGroupsForCopy(['General', ...response.data.subGroups]);
    };

    fetchSubGroupsForCopy();
  }, []);

  const handleCopyTemplate = () => {
    setIsCopyModalOpen(false);
    if (subgroupToCopyTo !== template.subGroup) {
      SaveWorkoutTemplateToSubgroup(subgroupToCopyTo);
    } else {
      displayNotification('error', 'cannot copy to same subgroup');
    }
  };

  return (
    <Container maxWidth="lg" component={'main'} className={classes.content}>
      <LoadingScreen open={loading} />
      {!isTemplateSection && (
        <>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}>
            <SubGroup
              user={user}
              selectedSubgroup={selectedSubgroup}
              setSelectedSubgroup={setSelectedSubgroup}
              onSubgroupsChanged={subgroups => {
                setSubgroups([]);
                setSubgroups(subgroups);
              }}
            />
            {!isMobile && (
              <Button
                className={classes.button}
                onClick={handlePreviewWeeklyWorkoutsPlan}
                data-test="createWorkout-previewWeeklyWorkoutsButton"
                variant="contained">
                Preview Weekly Workouts
              </Button>
            )}
          </Box>
          <WeekCalendar
            changeDate={changeDate}
            handleNextWeek={handleNextWeek}
            handlePreviousWeek={handlePreviousWeek}
            handleSelectedDateFromCalendar={handleSelectedDateFromCalendar}
            highlightedDate={workoutsPlanTemplate}
            weekDays={weeklyWorkoutsPlan}
          />
          <Typography
            className={classes.subTitle}
            data-test="createWorkout-title"
            variant="h3">
            Create a workout
          </Typography>
        </>
      )}
      <Grid container spacing={2} style={{ marginBottom: 8 }}>
        <Grid item sm={5} xs={12} style={{ minWidth: '250px' }}>
          {isMobile && (
            <MobileWorkoutTemplateSelection
              handleWorkoutTemplateDisplay={handleWorkoutTemplateDisplay}
              selectedSubgroup={selectedSubgroup}
              user={user}
            />
          )}
          <TextField
            InputLabelProps={{
              // className: classes.priamryTextColor,
              style: { fontWeight: 'bold' }
            }}
            InputProps={
              {
                // className: classes.priamryTextColor,
                // style: { fontWeight: 'bold' }
              }
            }
            className={classes.textField}
            id="workoutName"
            label={workoutName ? '' : 'Workout name'}
            margin="dense"
            onChange={handleWorkoutNameChange}
            value={workoutName}
            variant="outlined"
          />
        </Grid>
      </Grid>
      <Box
        display={'flex'}
        flexDirection={isMobile ? 'column' : 'row'}
        alignItems={isMobile ? 'center' : 'flex-start'}
        justifyContent={'center'}
        gridGap={16}>
        <Box flexGrow={1}>
          <div className={classes.buttons}>
            <Button
              className={`${classes.button} ${classes.fit_button} ${classes.btn_margin}`}
              onClick={addWarmUp}
              variant="contained">
              <TrendingUpOutlinedIcon className={classes.iconButton} />
              Add Warm Up
            </Button>

            <Button
              className={`${classes.button} ${classes.fit_button} ${classes.btn_margin}`}
              onClick={addRepeatStep}
              variant="contained">
              <RepeatIcon className={classes.iconButton} />
              Add repeat
            </Button>

            <Button
              className={`${classes.button} ${classes.fit_button} ${classes.btn_margin}`}
              onClick={addWorkoutStep}
              variant="contained">
              <AddOutlinedIcon className={classes.iconButton} />
              Add step
            </Button>

            <Button
              className={`${classes.button} ${classes.fit_button}`}
              onClick={addCoolDown}
              variant="contained">
              <TrendingDownOutlinedIcon className={classes.iconButton} />
              Add Cool Down
            </Button>
          </div>
          {steps.map((step, index) => {
            if (step.type == 'WorkoutStep')
              return (
                <WorkoutStepTemplate
                  key={index}
                  name={step.name}
                  removeStep={removeStep}
                  step={step}
                  // trigger={trigger}
                />
              );
            if (step.type == 'WorkoutRepeatStep')
              return (
                <RepeatStep
                  key={index}
                  removeStep={removeStep}
                  step={step}
                  // trigger={trigger}
                />
              );
          })}
          <div className={classes.mTop3}>
            <Typography className={classes.box_title} variant="h6">
              Notes
            </Typography>
            <TextareaAutosize
              aria-label="minimum height"
              className={classes.textArea}
              id="workoutNotes"
              minRows={4}
              onChange={handleWorkoutNotesChange}
              placeholder=""
              value={workoutNotes}
            />
          </div>
        </Box>
        <Box
          display={isMobile ? 'grid' : 'flex'}
          gridTemplateColumns={'1fr 1fr'}
          flexDirection={'column'}
          gridGap={16}
          width={isMobile ? '100%' : 'auto'}
          maxWidth={'550px'}>
          <Button
            className={`${classes.button}`}
            onClick={
              isTemplateSection
                ? handleSaveWorkoutTemplate
                : handleSaveWeeklyWorkoutsPlan
            }
            data-test="createWorkout-saveButton">
            Save
          </Button>
          <Button
            className={`${classes.button} ${classes.btn_remove}`}
            onClick={() => setIsDeleteWorkoutPlanModalOpen(true)}
            data-test="createWorkout-removeButton"
            variant="contained">
            Remove
          </Button>
          {!isTemplateSection && (
            <Button
              style={{ gridColumn: '1/ 3', width: '100%' }}
              className={classes.button}
              onClick={handleSaveWorkoutTemplate}
              data-test="createWorkout-saveWorkoutAsATemplateButton"
              variant="contained">
              Save workout as a template
            </Button>
          )}
          {isTemplateSection ? (
            showCopyTemplate && (
              <>
                {!isMobile && <Divider />}
                <Button
                  style={{ gridColumn: '1/ 3', width: '100%' }}
                  className={`${classes.button}`}
                  onClick={() => {
                    setIsCopyModalOpen(true);
                  }}
                  data-test="createWorkout-copyButton">
                  Copy Template
                </Button>
              </>
            )
          ) : (
            <>
              {!isMobile ? (
                <WorkoutTemplateSelection
                  handleWorkoutTemplateDisplay={handleWorkoutTemplateDisplay}
                  subgroups={subgroups}
                  selectedSubgroup={selectedSubgroup}
                  user={user}
                />
              ) : (
                <Button
                  className={classes.button}
                  style={{ gridColumn: '1/ 3', width: '100%' }}
                  onClick={handlePreviewWeeklyWorkoutsPlan}
                  data-test="createWorkout-previewWeeklyWorkoutsButton"
                  variant="contained">
                  Preview Weekly Workouts
                </Button>
              )}
            </>
          )}
        </Box>
      </Box>
      {!isTemplateSection && (
        <WeeklyPlanPreviewModal
          handleModalClose={handleModalClose}
          isModalOpen={isModalOpen}
          runnersList={runnersList}
          user={user}
          weeklyWorkoutTemplatesPlan={weeklyWorkoutsPlan}
          selectedSubgroup={selectedSubgroup}
          handleRemoveWeeklyWorkoutFromPlan={() =>
            setIsDeleteWorkoutPlanModalOpen(true)
          }
        />
      )}
      <SnackbarNotification
        isNotificationDisplayed={isNotification}
        setIsNotificationDisplayed={setIsNotification}
        message={notificationMessage}
        severity={notificationSeverity}
      />
      <Dialog
        open={isDeleteWorkoutPlanModalOpen}
        onClose={() => setIsDeleteWorkoutPlanModalOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          Remove this {isTemplateSection ? 'template' : 'workout'}?
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to remove this{' '}
            {isTemplateSection ? 'template' : 'workout'}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            data-test="createWorkout-Dialog-DialogActions-cancelRemoveWorkoutButton"
            onClick={() => setIsDeleteWorkoutPlanModalOpen(false)}>
            Cancel
          </Button>
          <Button
            data-test="createWorkout-Dialog-DialogActions-removeWorkoutButton"
            onClick={
              isTemplateSection
                ? handleDeleteWorkoutTemplate
                : handleRemoveWeeklyWorkoutFromPlan
            }
            autoFocus>
            Remove
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isCopyModalOpen}>
        <DialogTitle>Copy template to subgroup</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            select the subgroup to copy the template to
          </DialogContentText>
          <form className={classes.container}>
            <FormControl className={classes.formControl}>
              <InputLabel>Subgroup</InputLabel>
              <Select
                value={subgroupToCopyTo}
                onChange={event =>
                  setSubgroupToCopyTo(event.target.value || 'General')
                }>
                {subGroupsForCopy.map((value, index) => (
                  <MenuItem key={index} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsCopyModalOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCopyTemplate} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default CreateWorkout;
