// import React from 'react';
// import { useForm, ValidationError } from '@formspree/react';
// import { makeStyles } from '@material-ui/core/styles';
// import Modal from '@material-ui/core/Modal';
// import { Card } from '@material-ui/core';

// const useStyles = makeStyles(theme => ({
//   paper: {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     margin: theme.spacing(4)
//   },
//   scrollable: {
//     overflow: 'auto'
//   }
// }));

// function ContactForm() {
//   const classes = useStyles();
//   const [state, handleSubmit] = useForm('mzbnwbzy');
//   if (state.succeeded) {
//     return (
//       <>
//         <p>Thanks for letting us know!</p>
//         <p> We will get back to you...</p>;
//       </>
//     );
//   }
//   return (
//     <div>
//       <form className={classes.paper} onSubmit={handleSubmit}>
//         <label htmlFor="email">Email Address</label>
//         <input id="email" type="email" name="email" />
//         <ValidationError prefix="Email" field="email" errors={state.errors} />
//         <textarea id="message" name="message" />
//         <ValidationError
//           prefix="Message"
//           field="message"
//           errors={state.errors}
//         />
//         <button type="submit" disabled={state.submitting}>
//           Submit
//         </button>
//       </form>
//     </div>
//   );
// }

// const SupportRequestModal = props => {
//   const {
//     isSupportRequestModalOpen,
//     handleSupportRequestModalClose,
//     user
//   } = props;
//   const classes = useStyles();
//   // open is required for the MUI V4 modal component
//   const open = isSupportRequestModalOpen;

//   return (
//     <Modal
//       className={`${classes.paper}`}
//       onClose={handleSupportRequestModalClose}
//       open={isSupportRequestModalOpen}>
//       <Card>
//         <ContactForm />
//       </Card>
//     </Modal>
//   );
// };

// export default SupportRequestModal;

import Modal from '@material-ui/core/Modal';
import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import { makeStyles } from '@material-ui/core/styles';
import {
  TextField,
  Button,
  Card,
  CardContent,
  IconButton,
  Typography,
  Grid
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(4)
  },
  field: {
    marginBottom: theme.spacing(2),
    width: '80%'
  },
  button: {
    // margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    }
  },
  closeIcon: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2)
  },
  greeting: {
    width: '80%',
    paddingBottom: theme.spacing(1)
  },
  text: {
    width: '80%',
    paddingBottom: theme.spacing(2)
  },
  textAfterSent: {
    width: '80%',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    textAlign: 'center'
  }
}));

function ContactForm(props) {
  const { user } = props;

  const classes = useStyles();
  const [state, handleSubmit] = useForm('mzbnwbzy');
  if (state.succeeded) {
    return (
      <Typography variant="h4" className={classes.textAfterSent}>
        Thank you for reaching out! 🎉 <br />
        We've received your message and will review it shortly.
      </Typography>
    );
  }
  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <Typography variant="h4" className={classes.greeting}>
        Hello there, 👋
      </Typography>
      <Typography className={classes.text}>
        We're thrilled to hear from you! <br />
        Your feedback helps us build a better experience for you and all our
        users.
        <br />
        Please share your thoughts or any issues you're facing, and we'll do our
        best to assist you.
      </Typography>
      <TextField
        id="email"
        type="email"
        name="email"
        label="Email Address"
        variant="outlined"
        className={classes.field}
        value={user?.email || ''}
      />
      <ValidationError prefix="Email" field="email" errors={state.errors} />
      <TextField
        id="mobile"
        type="mobile"
        name="mobile"
        label="Mobile Number"
        variant="outlined"
        className={classes.field}
        value={user?.mobile || ''}
      />
      <ValidationError prefix="Mobile" field="mobile" errors={state.errors} />
      <TextField
        id="message"
        name="message"
        label="Message"
        variant="outlined"
        multiline
        minRows={4}
        className={classes.field}
      />
      <ValidationError prefix="Message" field="message" errors={state.errors} />
      <Button
        type="submit"
        disabled={state.submitting}
        className={classes.button}>
        Send Message
      </Button>
    </form>
  );
}

const SupportRequestModal = props => {
  const {
    isSupportRequestModalOpen,
    handleSupportRequestModalClose,
    user
  } = props;
  const classes = useStyles();

  return (
    <Modal
      className={classes.form}
      onClose={handleSupportRequestModalClose}
      open={isSupportRequestModalOpen}>
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardContent style={{ position: 'relative' }}>
              <IconButton
                className={classes.closeIcon}
                onClick={handleSupportRequestModalClose}>
                <CloseIcon />
              </IconButton>
              <ContactForm user={user} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default SupportRequestModal;
