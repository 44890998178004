import axios from 'api/axios';

const hasGarminOAuth = async email =>
  await axios.post('/hasGarminOAuth', { userEmail: email });

const oAuthGarmin = async userEmail => {
  const hasGarmin = await hasGarminOAuth(userEmail);
  if (hasGarmin.data) {
    alert(`Your garmin account (${userEmail}) is already synced`);
    return;
  }
  window.location.replace(
    `${process.env.REACT_APP_SERVER_URL}/connect/garmin?state=${userEmail}`
  );
};

export default oAuthGarmin;
