import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import { Password, Unsubscribe } from './components';
// import { Notifications } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Settings = props => {
  const { user } = props;
  const classes = useStyles();
  const [subscriptionID, setSubscriptionID] = useState(user.subscriptionID);

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        {user.role === 'trainer' && subscriptionID && (
          <Grid item md={6} xs={12}>
            <Unsubscribe setSubscriptionID={setSubscriptionID} user={user} />
          </Grid>
        )}
        <Grid item md={6} xs={12}>
          <Password user={user} />
        </Grid>
        {/* <Grid item md={7} xs={12}>
          <Notifications />
        </Grid> */}
      </Grid>
    </div>
  );
};

Settings.propTypes = {
  user: PropTypes.object
};

export default Settings;
