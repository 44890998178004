import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grow,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  TextField,
  makeStyles,
  withStyles
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DeleteIcon from '@material-ui/icons/Delete';
import axios from 'axios';
import {
  ArrowDownward,
  ArrowUpward,
  Check,
  Close,
  Edit,
  MoreHoriz
} from '@material-ui/icons';
import SnackbarNotification from 'components/SnackbarNotification';
import Tippy from '@tippyjs/react';

const useStyles = makeStyles(theme => ({
  subgroupTextField: {
    width: '100%'
  },
  subGroupNameContainer: {
    width: '100%',
    height: '60px',
    paddingLeft: '16px',
    display: 'flex',
    alignItems: 'center'
  },
  subGroupContainer: {
    position: 'relative',
    padding: '0 !important',
    background: theme.palette.common.white,
    '&:hover': {
      background: '#F5F5F5'
    }
  },
  addSubGroupModel: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '70px',
    gap: '10px',
    paddingBottom: '30px',
    overflow: 'hidden'
  },
  addSubGroupModelDelete: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '80px',
    gap: '10px'
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderBottom: '1px solid #BABABA'
  },
  addSubGroupBtn: {
    fontWeight: 700,
    color: theme.palette.primary.main,
    paddingBlock: '12px',
    background: 'none',
    width: '100%'
  },
  reorderIcon: {
    //position: 'absolute',
    height: '48px',
    borderRadius: '24px',
    borderTopRightRadius: '20px',
    borderBottomRightRadius: '20px',
    padding: '6px',
    zIndex: '-1',
    boxShadow:
      '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    '& button': {
      padding: '6px'
    }
  },
  saveNewOrder: {
    position: 'absolute',
    height: '48px',
    right: '0',
    bottom: '-54px',
    borderRadius: '24px',
    padding: '6px',
    '& button': {
      padding: '6px'
    }
  },
  subgroupItemsContainer: {
    maxHeight: '300px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.4em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      borderRadius: '2px'
    }
  }
}));

const StyledMenu = withStyles({
  paper: {
    overflow: 'visible',
    zIndex: '99'
  }
})(props => <Menu {...props} />);

/**
 * `requestCallback` is fired when a subgroup is renamed.
 *
 * @callback onRenameCallback
 * @param {string} oldSubgroup
 * @param {string} newSubgroup
 */

/**
 * `subgroupsCallback` is fired when the subgroups are fetched.
 *
 * @callback onSubgroupsCallback
 * @param {Array} subgroups
 */

/**
 * Functional component for managing subgroups and subgroup operations.
 * @param {Object} props - The props object.
 * @param {Object} props.user - The user object.
 * @param {String} props.selectedSubgroup - The currently selected subgroup.
 * @param {Function} props.setSelectedSubgroup - Function to set the selected subgroup.
 * @param {String} props.width - **(Optional)** The width of the component, defaults to '282px'.
 * @param {Object} props.style - **(Optional)** The style object for the component.
 * @param {onSubgroupsCallback} props.onSubgroupsChanged - **(Optional)** callback of when subgroups are fetched or updated.
 * @param {onRenameCallback} props.onSubgroupRename - **(Optional)**  callback of when a subgroup renamed.
 * @return {JSX.Element} The rendered JSX for subgroup management.
 *
 * ## **Usage**
 * it is recommended to set the default state of the selected subgroup to **'General'**
 * ```jsx
 * const [selectedSubgroup, setSelectedSubgroup] = useState('General');
 *
 * <SubGroup
 *    user={user}
 *    selectedSubgroup={selectedSubgroup}
 *    etSelectedSubgroup={setSelectedSubgroup}
 * />
 * ```
 */
const SubGroup = ({
  user,
  selectedSubgroup,
  setSelectedSubgroup,
  width = '282px',
  onSubgroupsChanged = () => {},
  onSubgroupRename = () => {},
  style = {}
}) => {
  const classes = useStyles();
  const [subgroups, setSubgroups] = useState(['General']);
  const [subgroupMenuOpenIndex, setSubgroupMenuOpenIndex] = useState(-1);
  const [edittingSubgroupNameIndex, setEditingSubgroupNameIndex] = useState(-1);
  const [renamedSubgroup, setRenamedSubgroup] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [newSubgroupName, setNewSubgroupName] = useState('');
  const [subgroupModalOpen, setSubgroupModalOpen] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [subgroupToDelete, setSubgroupToDelete] = useState('');
  const [renamingLoading, setRenamingLoading] = useState(false);

  const [isReordering, setIsReordering] = useState(false);
  const [reorderingLoading, setReorderingLoading] = useState(false);
  const [oldSubgroupOrder, setOldSubgroupOrder] = useState([]);

  useEffect(() => {
    if (!selectedSubgroup) setSelectedSubgroup('General');
    onSubgroupsChanged(subgroups);
    const fetchSubGroups = async () => {
      const payload = user.role == 'trainer' && { trainerUserId: user._id };
      const trainerUserId = payload.trainerUserId;
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/getSubgroups`,
        { trainerUserId }
      );
      // Need to change it and make sure every trainer has at least General subgroup in their subgroups when created
      // and that you cannot delete this subgroup
      setSubgroups(['General', ...response.data.subGroups]);
      onSubgroupsChanged(['General', ...response.data.subGroups]);
    };

    fetchSubGroups();
  }, []);

  const addSubgroup = async newSubgroup => {
    const payload = user.role == 'trainer' && { trainerUserId: user._id };
    if (user.role == 'trainer') {
      const response = await axios.patch(
        `${process.env.REACT_APP_SERVER_URL}/createSubgroup`,
        {
          id: payload.trainerUserId,
          subgroup: newSubgroup
        }
      );
    }
  };

  const handleClick = event => {
    setSubgroupMenuOpenIndex(-1);
    setEditingSubgroupNameIndex(-1);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    handleCancelNewOrder();
    setAnchorEl(null);
  };

  const [isNotification, setIsNotification] = React.useState(false);
  const [notificationSeverity, setNotificationSeverity] = React.useState('');
  const [notificationMessage, setNotificationMessage] = React.useState('');

  const displayNotification = (severity, message) => {
    setNotificationSeverity(severity);
    setNotificationMessage(message);
    setIsNotification(true);
  };

  const handleShowMoreOptions = index => {
    setEditingSubgroupNameIndex(-1);
    setSubgroupMenuOpenIndex(index);
  };
  const handleEditSubgroupName = index => {
    setEditingSubgroupNameIndex(index);
    setRenamedSubgroup(subgroups[index]);
  };

  const handleClickSubgroup = index => {
    if (edittingSubgroupNameIndex != index) {
      setSelectedSubgroup(subgroups[index]);
      handleClose();
    }
  };

  const handleCancelEdit = () => {
    setSubgroupMenuOpenIndex(-1);
    setEditingSubgroupNameIndex(-1);
    setRenamedSubgroup('');
  };

  const renameSubgroup = () => {
    const rename = async () => {
      if (!renamedSubgroup) return;
      if (edittingSubgroupNameIndex == -1) return;
      const subgroup = subgroups[edittingSubgroupNameIndex];
      setRenamingLoading(true);

      const payload = user.role == 'trainer' && { trainerUserId: user._id };
      if (user.role == 'trainer') {
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/workout/renameSubgroup`,
          {
            trainerId: payload.trainerUserId,
            subgroup: subgroup,
            newSubgroup: renamedSubgroup
          },
          {
            validateStatus: () => true
          }
        );
        if (response.status <= 500) {
          displayNotification(
            'error',
            'an error has occurred, please try again later'
          );
        } else if (response.status <= 421) {
          displayNotification('error', response.data.msg);
        }
        if (response.status == 200) {
          const newSubgroups = subgroups;
          newSubgroups[edittingSubgroupNameIndex] = renamedSubgroup;
          setSubgroups(newSubgroups);
          setSelectedSubgroup(renamedSubgroup);
          onSubgroupsChanged(newSubgroups);
          setEditingSubgroupNameIndex(-1);
          setSubgroupMenuOpenIndex(-1);
          onSubgroupRename(subgroup, renamedSubgroup);
          setRenamedSubgroup('');
          displayNotification('success', 'subgroup renamed successfully');
          handleClose();
        }
        setRenamingLoading(false);
      }
    }
    rename()
  };

  const handleOpenSubgroupModal = () => {
    setSubgroupModalOpen(true);
  };

  const handleCloseSubgroupModal = () => {
    setSubgroupModalOpen(false);
  };

  const handleAddSubgroup = newSubgroup => {
    addSubgroup(newSubgroupName);
    setSubgroups([...subgroups, newSubgroup]);
    onSubgroupsChanged([...subgroups, newSubgroup]);
    setSelectedSubgroup(newSubgroup);
    setNewSubgroupName('');
    handleCloseSubgroupModal();
    handleClose();
  };

  const handleRemoveSubgroup = subgroup => {
    setSubgroupToDelete(subgroup);
    setDeleteConfirmationOpen(true);
  };

  const handleCloseDeleteConfirmation = () => {
    setDeleteConfirmationOpen(false);
  };

  const handleDeleteSubgroup = subgroup => {
    deleteSubgroup(subgroup);
    setSubgroups(subgroups.filter(sub => sub !== subgroup));
    onSubgroupsChanged(subgroups.filter(sub => sub !== subgroup));
    handleCloseDeleteConfirmation();
    handleClose();
    setSelectedSubgroup('General');
  };

  const deleteSubgroup = async subGroup => {
    const payload = user.role == 'trainer' && { trainerUserId: user._id };
    if (user.role == 'trainer') {
      const response = await axios.patch(
        `${process.env.REACT_APP_SERVER_URL}/removeSubgroup`,
        {
          trainerUserId: payload.trainerUserId,
          subGroup
        }
      );
    }
  };

  const arraysHaveSameOrder = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false;
    }
    return true;
  };

  const handleReorderUp = index => {
    if (index < 2) return;
    if (!isReordering) {
      setIsReordering(true);
      setOldSubgroupOrder([...subgroups]);
    }
    const newSubgroups = [...subgroups];
    const temp = newSubgroups[index - 1];
    newSubgroups[index - 1] = newSubgroups[index];
    newSubgroups[index] = temp;
    setSubgroupMenuOpenIndex(index - 1);
    setSubgroups(newSubgroups);
  };

  const handleReorderDown = index => {
    if (index == subgroups.length - 1) return;
    if (!isReordering) {
      setIsReordering(true);
      setOldSubgroupOrder([...subgroups]);
    }
    const newSubgroups = [...subgroups];
    const temp = newSubgroups[index + 1];
    newSubgroups[index + 1] = newSubgroups[index];
    newSubgroups[index] = temp;
    setSubgroupMenuOpenIndex(index + 1);
    setSubgroups(newSubgroups);
  };

  const handleSaveNewOrder = () => {
    const saveNewOrder = async () => {
      if (!isReordering) return;
      if (user.role != 'trainer') return;
      setReorderingLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/workout/reorderSubgroups`,
        {
          trainerUserId: user._id,
          subgroups: subgroups,
        },
        {
          validateStatus: () => true
        }
      );
      if (response.status <= 500) {
        displayNotification(
          'error',
          'an error has occurred, please try again later'
        );
        handleCancelNewOrder()
      } else if (response.status <= 421) {
        displayNotification('error', response.data.msg);
        handleCancelNewOrder()
      }
      if (response.status == 200) {
        setOldSubgroupOrder([...subgroups]);
        setIsReordering(false);
        setSubgroupMenuOpenIndex(-1);
        onSubgroupsChanged([...subgroups]);
        setSubgroups([...subgroups]);
        displayNotification('success', 'subgroups reordered successfully');
      }
      setReorderingLoading(false);
    }
    saveNewOrder()
  };

  const handleCancelNewOrder = () => {
    if (!isReordering) return;
    setSubgroups(oldSubgroupOrder);
    setSubgroupMenuOpenIndex(-1);
    setIsReordering(false);
  };

  // useEffect(() => {
  //   const subgroupContainer = document.querySelector('.menu-subgroups-container');
  //   if (!subgroupContainer) return;
  //   const observer = new IntersectionObserver(
  //     ([entry]) => {
  //       const index = entry.target.getAttribute('data-index')
  //       const element = document.querySelector(`.subgroup-item-popper-${index}`)
  //       if (!element) return;
  //       element.classList.toggle('hidden', !entry.isIntersecting)
  //     },
  //     {
  //       root: subgroupContainer,
  //       rootMargin: '0px',
  //       threshold: 0.5, // 50% of target visible
  //     }
  //   );
  //   subgroups.forEach((subgroup, index) => {
  //     const ref = document.querySelector(`.subgroup-item-${index}`)
  //     if (!ref) return;
  //     observer.observe(ref);
  //   });

  //   return () => {
  //     observer.disconnect();
  //   }
  // }, [subgroups, subgroupMenuOpenIndex, edittingSubgroupNameIndex, Boolean(anchorEl)])

  return (
    <>
      <SnackbarNotification
        isNotificationDisplayed={isNotification}
        message={notificationMessage}
        setIsNotificationDisplayed={setIsNotification}
        severity={notificationSeverity}
      />
      <Box style={{ width: width, ...style }}>
        <TextField
          InputProps={{
            readOnly: true,
            endAdornment: <ArrowDropDownIcon />
          }}
          className={classes.subgroupTextField}
          data-test={'SubGroup-textFieldMenu'}
          id="outlined-basic"
          label="Sub Group"
          margin="dense"
          onClick={handleClick}
          value={selectedSubgroup}
          variant="outlined"
        />
        <StyledMenu
          anchorEl={anchorEl}
          keepMounted
          onClose={handleClose}
          open={Boolean(anchorEl)}
          style={{ top: '30px' }}
        >

          <MenuItem
            style={{ width: width, minWidth: '282px', height: 0, padding: 0 }}
          />
          <div className={`${classes.subgroupItemsContainer} menu-subgroups-container`}>
            {subgroups &&
              subgroups.map((subgroup, index) =>(
                <Tippy
                  visible={subgroupMenuOpenIndex == index &&
                    edittingSubgroupNameIndex != index && Boolean(anchorEl)}
                  placement="left"
                  interactive
                  theme='invisible'
                  className={`subgroup-item-popper-${index}`}
                  key={index}
                  zIndex={-1}
                  moveTransition='transform 0.02s ease-out'
                  content={
                    <Paper
                      //style={{ transform: 'translateX(20px)' }}
                      className={classes.reorderIcon}
                    >
                      {index > 1 && (
                        <IconButton
                          onClick={() => handleReorderUp(index)}
                        >
                          <ArrowUpward />
                        </IconButton>
                      )}
                      {index != subgroups.length - 1 && (
                        <IconButton onClick={() => handleReorderDown(index)}>
                          <ArrowDownward/>
                        </IconButton>
                      )}
                    </Paper>
                  }
                >
                  <MenuItem
                    className={`${classes.subGroupContainer} subgroup-item-${index}`}
                    data-test={`SubGroup-menuItem-${subgroup}`}
                    data-index={index}
                    key={index}
                    onKeyDown={e => e.stopPropagation()}
                    style={{
                      cursor: 'auto',
                      width: '100%',
                      overflow: 'visible'
                    }}
                  >
                    {/* {orderToolContainerRef.current &&
                      createPortal(
                        <div style={{position: 'fixed'}} className={`subgroup-item-popper-${index}`}>
                          <Grow
                            in={
                              subgroupMenuOpenIndex == index &&
                              edittingSubgroupNameIndex != index
                            }
                            style={{ transformOrigin: 'center right' }}
                            {...(subgroupMenuOpenIndex == index &&
                            edittingSubgroupNameIndex != index
                              ? { timeout: 400 }
                              : {})}>
                            
                          </Grow>
                        </div>,
                        orderToolContainerRef.current
                      )} */}

                    <div
                      className={classes.subGroupNameContainer}
                      onClick={() => handleClickSubgroup(index)}
                    >
                      {edittingSubgroupNameIndex == index ? (
                        <TextField
                          className={classes.subgroupTextField}
                          data-test={`SubGroup-textField-${subgroup}`}
                          margin="dense"
                          onChange={e => setRenamedSubgroup(e.target.value)}
                          value={renamedSubgroup}
                          variant="standard"
                        />
                      ) : (
                        <>{subgroup} </>
                      )}
                    </div>
                    {/* <IconButton
                    data-test={`SubGroup-deleteSubGroupIcon-${subgroup}`}
                    onClick={() => handleRemoveSubgroup(subgroup)}>
                    <DeleteIcon />
                  </IconButton> */}
                    {subgroup != 'General' &&
                      (edittingSubgroupNameIndex == index ? (
                        // edit mode
                        <>
                          {renamingLoading ? (
                            <Grow in>
                              <IconButton>
                                <CircularProgress size={20} />
                              </IconButton>
                            </Grow>
                          ) : (
                            <Grow in>
                              <IconButton
                                data-test={`SubGroup-renameSubGroupIcon-${subgroup}`}
                                onClick={renameSubgroup} // arrow function to prevent app from freezing while awaiting response
                              >
                                <Check />
                              </IconButton>
                            </Grow>
                          )}
                          <Grow in>
                            <IconButton
                              data-test={`SubGroup-cancelSubGroupIcon-${subgroup}`}
                              onClick={handleCancelEdit}
                            >
                              <Close />
                            </IconButton>
                          </Grow>
                        </>
                      ) : subgroupMenuOpenIndex == index ? (
                        // option menu open
                        <>
                          <Grow in>
                            <IconButton
                              data-test={`SubGroup-moreSubGroupIcon-${subgroup}`}
                              onClick={() => handleEditSubgroupName(index)}
                            >
                              <Edit />
                            </IconButton>
                          </Grow>

                          <Grow in>
                            <IconButton
                              data-test={`SubGroup-deleteSubGroupIcon-${subgroup}`}
                              onClick={() => handleRemoveSubgroup(subgroup)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grow>
                        </>
                      ) : (
                        // option menu closed
                        <Grow in>
                          <IconButton
                            data-test={`SubGroup-moreSubGroupIcon-${subgroup}`}
                            onClick={() => handleShowMoreOptions(index)}
                          >
                            <MoreHoriz />
                          </IconButton>
                        </Grow>
                      ))}
                  </MenuItem>
                </Tippy>
              ))}
          </div>
          <Divider className={classes.divider} />
          <div className={classes.addSubGroupBtnContainer}>
            <MenuItem
              className={classes.addSubGroupBtn}
              data-test={'SubGroup-addSubGroup-menuItem'}
              onClick={handleOpenSubgroupModal}
            >
              Add Sub-Group
              <AddIcon style={{ marginLeft: 'auto', marginRight: '6px' }} />
            </MenuItem>
          </div>
          <Grow
            in={
              isReordering && !arraysHaveSameOrder(subgroups, oldSubgroupOrder)
            }
          >
            <Paper className={classes.saveNewOrder}>
              { reorderingLoading ? (
                <Grow in>
                  <IconButton>
                    <CircularProgress size={24} />
                  </IconButton>
                </Grow>
              ) : (
                <>
                <IconButton onClick={handleSaveNewOrder}>
                  <Check  />
                </IconButton>
                <IconButton onClick={handleCancelNewOrder}>
                  <Close  />
                </IconButton>
                </>
                )
              }
            </Paper>
          </Grow>
        </StyledMenu>
      </Box>

      {/* Subgroup Modal */}
      <Dialog onClose={handleCloseSubgroupModal} open={subgroupModalOpen}>
        <DialogTitle>Add Sub-Group</DialogTitle>
        <DialogContent className={classes.addSubGroupModel}>
          <TextField
            InputProps={{
              style: {
                height: '35px',
                padding: '8px'
              }
            }}
            className={classes.addSubGroupField}
            data-test={'SubGroup-addSubGroup-textField'}
            onChange={e => setNewSubgroupName(e.target.value)}
            value={newSubgroupName}
            variant="outlined"
          />
          <Button
            data-test={'SubGroup-addSubGroup-addButton'}
            onClick={() => handleAddSubgroup(newSubgroupName)}
          >
            Add
          </Button>
        </DialogContent>
      </Dialog>

      <Dialog
        data-test={'SubGroup-deleteSubGroupModal'}
        onClose={handleCloseDeleteConfirmation}
        open={deleteConfirmationOpen}
      >
        <DialogTitle>{`Are you sure you want to delete ${subgroupToDelete}?`}</DialogTitle>
        <DialogContent>
          Note: All runners of {subgroupToDelete} sub-group will be switched
          automatically to the General sub-group.
        </DialogContent>
        <DialogContent className={classes.addSubGroupModelDelete}>
          <Button
            data-test={'SubGroup-deleteSubGroupModal-confirmButton'}
            onClick={() => handleDeleteSubgroup(subgroupToDelete)}
          >
            Confirm
          </Button>
          <Button
            data-test={'SubGroup-deleteSubGroupModal-cancelButton'}
            onClick={handleCloseDeleteConfirmation}
          >
            Cancel
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SubGroup;
