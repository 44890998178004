/* eslint-disable react/no-multi-comp */
import React, { useState, useEffect, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select
} from '@material-ui/core';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import WorkoutAnalysisModal from '../WorkoutAnalysis/WorkoutAnalysisModal';
import interactionPlugin from '@fullcalendar/interaction';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    position: 'absolute',
    top: 10,
    right: 10
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

function TooltipSlice(icon, data) {
  return `<div class="tooltip-slice"><span style="font-size: 13pt" class="material-icons">${icon}</span> ${data}</div>`;
}
function isMobile() {
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  } else {
    return false;
  }
}
const getAvgPace = pace => {
  // reused code
  const minutes = Math.floor(pace);
  // Get the decimal part and convert it to seconds
  const decimal = pace - minutes;
  const seconds = Math.round(decimal * 60);
  // Format the seconds to always have two digits
  const formattedSeconds = seconds < 10 ? '0' + seconds : seconds;
  // Return the pace in 'mm:ss' format
  return `${minutes}:${formattedSeconds}`;
};

// convert number of seconds to hh:mm:ss format
const secondsToHms = totalSeconds => {
  totalSeconds = Number(totalSeconds);
  const h = Math.floor(totalSeconds / 3600);
  const m = Math.floor((totalSeconds % 3600) / 60);
  const s = Math.floor((totalSeconds % 3600) % 60);

  const hDisplay = h + ':';
  const mDisplay = m > 9 ? m + ':' : '0' + m + ':';
  const sDisplay = s > 9 ? s : '0' + s;
  return hDisplay + mDisplay + sDisplay;
};

function Tooltip(activity, id) {
  return `
  <span style="font-size: 12pt">${activity.activityName}</span>
  ${TooltipSlice('alarm', secondsToHms(activity.durationInSeconds))}
  ${TooltipSlice(
    'timeline',
    (activity.distanceInMeters / 1000).toFixed(2) + ' km'
  )}
  ${TooltipSlice(
    'speed',
    getAvgPace(activity.averagePaceInMinutesPerKilometer)
  )}
  ${
  isMobile()
    ? `<button class='tooltip-button tooltip-${id}'>To Full Analysis</button>`
    : ''
}
  `;
}

function RunnerSelector({
  runners,
  selectedRunner,
  setSelectedRunner,
  setRunnerName
}) {
  // calendar library doesn't support having custom elements in the header bar
  // time to cheese it and forcefully put one there

  const classes = useStyles();

  const handleChange = event => {
    runners.forEach(runner => {
      if (runner.id == event.target.value) {
        setRunnerName(`${runner.firstName} ${runner.lastName}`);
        setSelectedRunner(runner);
        return;
      }
    });
  };
  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel>Select Runner</InputLabel>
      <Select
        label="Select Runner"
        onChange={handleChange}
        value={selectedRunner ? selectedRunner.id : ''}>
        {runners.map((value, index) => (
          <MenuItem
            key={index}
            value={value.id}>{`${value.firstName} ${value.lastName}`}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

function MonthView({ user }) {
  const [padding, setPadding] = useState(4);
  const [modalSize, setModalSize] = useState('partial'); // 'partial' or 'full'

  const [selectedRunner, setSelectedRunner] = useState();
  const [runnerName, setRunnerName] = useState();
  const [selectedActivity, setSelectedActivity] = useState();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [calendarApi, setCalendarApi] = useState();

  const [runners, setRunners] = useState([]);
  const [events, setEvents] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const classes = useStyles();

  const RootBox = useRef(null);
  const Calendar = useRef(null);
  const onResize = () => {
    if (!RootBox.current) return;
    if (RootBox.current.offsetWidth < 800) {
      setPadding(0);
    } else {
      setPadding(4);
    }
    if (RootBox.current.offsetWidth < 500) {
      setModalSize('full');
    } else {
      setModalSize('partial');
    }

    Calendar.current.getApi().render(); // force render to update to new padding
  };

  const initilizeCalendar = async () => {
    if (user.role == 'runner') {
      fetchData();
      return;
    }
    setLoading(true);
    const runnersResponse = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/runners`,
      { trainerUserId: user._id }
    );
    const runners = runnersResponse.data;

    const parsedRunnerData = [];
    for (let i = 0; i < runners.length; i++) {
      const runner = runners[i];
      if (!runner.isActive) continue;
      parsedRunnerData.push({
        firstName: runner.firstName,
        lastName: runner.lastName,
        id: runner.runnerId
      });
    }

    setRunners(parsedRunnerData);
    if (parsedRunnerData.length > 0) {
      setRunnerName(
        `${parsedRunnerData[0].firstName} ${parsedRunnerData[0].lastName}`
      );
      setSelectedRunner(parsedRunnerData[0]);
    }
    fetchData();
  };

  const fetchData = async () => {
    setLoading(true);
    if (!calendarApi) return;
    const activeRange =
      calendarApi.currentDataManager.state.dateProfile.activeRange;
    const activityResponse = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/activity/getSummaries`,
      {
        userId: user._id,
        range: { from: activeRange.start, to: activeRange.end }
      }
    );
    const data = activityResponse.data;
    setLoading(false);
    setData(data);
  };
  const updateCalendar = () => {
    // filter workouts and format to work with calendar
    let filteredWorkouts = []; // filtered and parsed workouts variable
    if (!selectedRunner) {
      setEvents([]);
      return;
    }
    for (let i = 0; i < data.length; i++) {
      const activity = data[i];
      const runner = activity.runnerId;

      if (user.role == 'trainer' && runner != selectedRunner.id) continue;
      filteredWorkouts.push({
        id: activity._id,
        title: activity.activityName,
        start: new Date(activity.startTimeInSeconds * 1000),
        allDay: true,
        extendedProps: {
          tooltip: Tooltip(activity, activity.activityId),
          activityId: activity.activityId
        }
      });
    }
    setEvents(filteredWorkouts);
  };

  const handleEventClick = info => {
    if (isMobile()) return;
    setSelectedActivity(info.event.extendedProps.activityId);
    setIsModalOpen(true);
  };

  const handleModalClose = () => setIsModalOpen(false);
  const handleTooltipButtonClick = id => {
    setSelectedActivity(id);
    setIsModalOpen(true);
  };
  const mountTooltip = info => {
    tippy(info.el, {
      content: info.event.extendedProps.tooltip,
      allowHTML: true,
      maxWidth: 400,
      interactive: isMobile(),
      onMount: () => {
        if (isMobile())
          document
            .querySelector(`.tooltip-${info.event.extendedProps.activityId}`)
            .addEventListener('click', () =>
              handleTooltipButtonClick(info.event.extendedProps.activityId)
            );
      },
      onHide: () => {
        if (isMobile())
          document
            .querySelector(`.tooltip-${info.event.extendedProps.activityId}`)
            .removeEventListener('click', () =>
              handleTooltipButtonClick(info.event.extendedProps.activityId)
            );
      }
    });
  };

  const handleDateChange = info => {
    fetchData();
  };
  // initial calendar setup
  //(async funtion so you have to call it in a none async funtion)
  useEffect(() => {
    setCalendarApi(Calendar.current.getApi());
  }, [Calendar]);

  useEffect(() => {
    initilizeCalendar();
    if (user.role == 'runner') {
      setSelectedRunner({
        firstName: user.firstName,
        lastName: user.lastName,
        id: user.runnerId
      });
      setRunnerName(`${user.firstName} ${user.lastName}`);
    }
  }, [calendarApi]);

  // on calendar update
  // (this is for automatic updates when selected runner is changed)
  useEffect(updateCalendar, [data, selectedRunner]);

  // resize listener
  useEffect(() => {
    window.addEventListener('resize', onResize);
    onResize();
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [RootBox, onResize]);

  return (
    <Box ref={RootBox} padding={padding}>
      <Paper style={{ maxWidth: '1000px', position: 'relative' }}>
        <Box padding={padding ? padding : 1}>
          <FullCalendar
            contentHeight="auto"
            firstDay={0} // 0 = sunday, 1 = monday...
            initialView="dayGridMonth"
            plugins={[dayGridPlugin, interactionPlugin]}
            ref={Calendar}
            eventDidMount={mountTooltip}
            headerToolbar={{
              start: 'prev title next',
              end: ''
            }}
            events={events}
            datesSet={handleDateChange}
            eventClick={handleEventClick}
          />
          { user.role == 'trainer' && (
            runners.length > 0 ? (
              <RunnerSelector
                runners={runners}
                selectedRunner={selectedRunner}
                setSelectedRunner={setSelectedRunner}
                setRunnerName={setRunnerName}
              />
            ) : loading ? (
              <div className={classes.formControl}>Loading...</div>
            ) : (
              <div className={classes.formControl}>You have No Runnners</div>
            )
          )}
          {loading && (
            <div className={classes.formControl} style={{ left: 0 }}>
              <CircularProgress size={'1rem'} />
            </div>
          )}
          <WorkoutAnalysisModal
            activityId={selectedActivity}
            modalSize={modalSize}
            handleModalClose={handleModalClose}
            isModalOpen={isModalOpen}
            runnerName={runnerName}
            user={user}
          />
        </Box>
      </Paper>
    </Box>
  );
}

export default MonthView;
