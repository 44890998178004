import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import MainWeeklyWorkoutPlan from '../../../WorkoutPlan/components/MainWeeklyWorkoutPlan';
import { Card } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  scrollableModal: {
    overflow: 'scroll'
  }
}));

const convertPaceToSec = (minutes, seconds) => minutes * 60 + seconds;

const WeeklyPlanPreviewModal = props => {
  const {
    user,
    isModalOpen,
    handleModalClose,
    weeklyWorkoutTemplatesPlan,
    runnersList,
    selectedSubgroup
  } = props;

  const classes = useStyles();

  const [weeklyWorkoutPlan, setWeeklyWorkoutPlan] = useState({});

  const createRunnersWorkoutsPlan = (
    runnersList,
    weeklyWorkoutTemplatesPlan
  ) => {
    if (!runnersList || !weeklyWorkoutTemplatesPlan) return [];

    const selectedSubgroupRunners = runnersList.filter(
      runner => runner.subGroup === selectedSubgroup
    );

    const updatedListOfRunners = selectedSubgroupRunners.map(runner => {
      const runnerPaceInSeconds = convertPaceToSec(
        runner.basePace.minutes,
        runner.basePace.seconds
      );

      const runnerWorkoutsPlan = weeklyWorkoutTemplatesPlan.map(workout => {
        if (workout.isWorkout) {
          const workoutTemplate = workout.workoutTemplate;

          if (workoutTemplate) {
            const workoutSteps = workoutTemplate.steps.map(step => {
              if (
                step.intensity === 'WARMUP' ||
                step.intensity === 'COOLDOWN'
              ) {
                return { ...step, runnerPace: 'EASY' };
              }

              if (step.targetValue && !isNaN(step.targetValue)) {
                if (step.targetType === 'HEART_RATE') {
                  switch (step.targetValue) {
                    case 1:
                      return { ...step, runnerPace: 'Zone 1 (Warm-Up)' };
                    case 2:
                      return { ...step, runnerPace: 'Zone 2 (Easy)' };
                    case 3:
                      return { ...step, runnerPace: 'Zone 3 (Aerobic)' };
                    case 4:
                      return { ...step, runnerPace: 'Zone 4 (Threshold)' };
                    case 5:
                      return { ...step, runnerPace: 'Zone 5 (Maximum)' };
                  }
                }

                const runnerPace =
                  parseInt(step.targetValue) + runnerPaceInSeconds;
                return { ...step, runnerPace };
              }

              if (step.targetValue && isNaN(step.targetValue)) {
                return { ...step, runnerPace: step.targetValue };
              }

              if (step.type === 'WorkoutRepeatStep') {
                const repeatedSteps = step.steps.map(repeatedStep => {
                  if (
                    repeatedStep.targetValue &&
                    !isNaN(repeatedStep.targetValue)
                  ) {
                    if (repeatedStep.targetType === 'HEART_RATE') {
                      switch (repeatedStep.targetValue) {
                        case 1:
                          return {
                            ...repeatedStep,
                            runnerPace: 'Zone 1 (Warm-Up)'
                          };
                        case 2:
                          return {
                            ...repeatedStep,
                            runnerPace: 'Zone 2 (Easy)'
                          };
                        case 3:
                          return {
                            ...repeatedStep,
                            runnerPace: 'Zone 3 (Aerobic)'
                          };
                        case 4:
                          return {
                            ...repeatedStep,
                            runnerPace: 'Zone 4 (Threshold)'
                          };
                        case 5:
                          return {
                            ...repeatedStep,
                            runnerPace: 'Zone 5 (Maximum)'
                          };
                      }
                    }

                    const runnerPace =
                      parseInt(repeatedStep.targetValue) + runnerPaceInSeconds;
                    return { ...repeatedStep, runnerPace };
                  }
                  if (
                    repeatedStep.targetValue &&
                    isNaN(repeatedStep.targetValue)
                  ) {
                    return {
                      ...repeatedStep,
                      runnerPace: repeatedStep.targetValue
                    };
                  }
                  return repeatedStep;
                });

                return { ...step, steps: repeatedSteps };
              }

              return step;
            });

            const updatedWorkoutTemplate = {
              ...workoutTemplate,
              steps: workoutSteps
            };

            return { ...workout, workoutTemplate: updatedWorkoutTemplate };
          }
        }

        return workout;
      });

      return { ...runner, runnerWorkoutsPlan };
    });

    return updatedListOfRunners;
  };

  useEffect(() => {
    const updatedListOfRunners = createRunnersWorkoutsPlan(
      runnersList,
      weeklyWorkoutTemplatesPlan
    );
    if (updatedListOfRunners) setWeeklyWorkoutPlan(updatedListOfRunners);
  }, [runnersList, weeklyWorkoutTemplatesPlan]);

  return (
    <Modal
      className={classes.scrollableModal}
      open={isModalOpen}
      onClose={handleModalClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{
        marginLeft: '7.5%',
        marginRight: '7.5%'
      }}>
      <Card>
        <MainWeeklyWorkoutPlan
          user={user}
          handleModalClose={handleModalClose}
          weeklyWorkoutPlan={weeklyWorkoutPlan}
          type={'WeeklyPlanPreviewModal'}
        />
      </Card>
    </Modal>
  );
};

export default WeeklyPlanPreviewModal;
