import 'date-fns';
import React, { useState } from 'react';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

export default function DatePicker(props) {
  const { setDate } = props;
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = date => {
    setDate(date);
    setSelectedDate(date);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        autoOk={true}
        disableToolbar
        id="date-picker-inline"
        format="MM/dd/yyyy"
        margin="normal"
        maxDate={new Date()}
        label="Choose day/week"
        value={selectedDate}
        onChange={handleDateChange}
        KeyboardButtonProps={{
          'aria-label': 'change date'
        }}
        variant="inline"
      />
    </MuiPickersUtilsProvider>
  );
}
