import DateFnsUtils from '@date-io/date-fns';
import { Tooltip, createTheme } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import { startOfWeek } from 'date-fns';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './CustomCalendar.scss';

const materialTheme = createTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#3F51B5'
      }
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: 'white',
        color: '#3F51B5'
      }
    }
  }
});

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

const formatDate = isoDate => {
  const dateObj = new Date(isoDate);
  return dateObj;
};

export default function CustomCalendar(props) {
  const {
    open,
    onClose,
    weekData,
    handleSelectedDateFromCalendar,
    higlightedDates,
    specificDate,
    setSpecificDate
  } = props;

  const classes = useStyles();

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [startDateOfWeek, setStartWeekDate] = useState(new Date());

  useEffect(() => {
    if (weekData.length > 0) {
      const minDate = new Date(
        Math.min(...weekData.map(day => day.dateObject.getTime()))
      );

      setSelectedDate(
        new Date(
          minDate.getFullYear(),
          minDate.getMonth(),
          specificDate.getDate() || minDate.getDate()
        )
      );
    }
  }, [weekData]);

  const formatedHighlightedDates = higlightedDates.map(date =>
    formatDate(date.dateObject)
  );

  const isDayHighlighted = date => {
    return formatedHighlightedDates.some(
      day => day.toDateString() === date.toDateString()
    );
  };
  const getStartOfWeek = date => {
    return startOfWeek(date, { weekStartsOn: 0 }); // 1 for Monday, adjust as needed
  };

  const onSelectDate = date => {
    setSpecificDate(date);
    setSelectedDate(date);
    const startOfWeek = getStartOfWeek(date);
    setStartWeekDate(startOfWeek);
    handleSelectedDateFromCalendar(startOfWeek);
    onClose();
  };

  const handleOnCalendarClose = () => {
    onClose();
  };

  return (
    <Backdrop className={classes.backdrop} open={open}>
      <div
        className="date-picker-calendar-container"
        data-test={'customCalendar-datePickerComponent'}>
        <Tooltip
          arrow
          style={{ cursor: 'pointer' }}
          placement="top"
          title="Close Calendar">
          <CloseIcon
            className="closeIcon"
            data-test="customCalendar-closeCalendarIconButton"
            fontSize="medium"
            onClick={handleOnCalendarClose}
          />
        </Tooltip>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <ThemeProvider theme={materialTheme}>
            <DatePicker
              selected={selectedDate}
              onChange={onSelectDate}
              dayClassName={date =>
                isDayHighlighted(date) ? 'highlighted-day' : ''
              }
              inline
            />
          </ThemeProvider>
        </MuiPickersUtilsProvider>
      </div>
    </Backdrop>
  );
}
