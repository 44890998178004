import React, { useState } from 'react';
import axios from 'axios';

const PaymentPricingSection = props => {
  const { user } = props;
  const [isMonth, setIsMonth] = useState(true);

  const handleMonthlyClick = () => setIsMonth(true);
  const handleYearlyClick = () => setIsMonth(false);

  const subscribeNow = async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/payment`,
      {
        isMonthlyPlan: isMonth,
        userId: user._id
      }
    );
    console.log(response.data.redirectURL);
    if (response.data.redirectURL) {
      window.location.href = response.data.redirectURL;
    }
  };

  return (
    <section id="pricing" className="pricing-section">
      <div className="row justify-content-center">
        <div className="pricing-nav-wrapper mb-20">
          <ul className="nav nav-pills" id="pills-tab" role="tablist">
            <li role="presentation">
              <a
                onClick={handleMonthlyClick}
                className={isMonth ? 'active' : ''}
                style={{ cursor: 'pointer' }}
                id="pills-month-tab"
                data-bs-toggle="pill"
                role="tab"
                aria-controls="pills-month"
                aria-selected="true">
                Monthly
              </a>
            </li>
            <li role="presentation">
              <a
                className={isMonth ? '' : 'active'}
                onClick={handleYearlyClick}
                id="pills-year-tab"
                data-bs-toggle="pill"
                role="tab"
                style={{ cursor: 'pointer' }}
                aria-controls="pills-year"
                aria-selected="false">
                Yearly
              </a>
            </li>
          </ul>
        </div>
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-month"
            role="tabpanel"
            aria-labelledby="pills-month-tab">
            <div className="row justify-content-center">
              <div className="col-lg-10 col-md-10 col-sm-12">
                <div className="single-pricing">
                  <div className="pricing-header">
                    {isMonth ? (
                      <h1 className="price">
                        <s style={{ color: 'lightGrey' }}>$39.99</s>&nbsp;$19.99
                      </h1>
                    ) : (
                      <h1 className="price">
                        <s style={{ color: 'lightGrey' }}>$480</s>&nbsp;$239
                      </h1>
                    )}
                    <h3 className="package-name">Business Account</h3>
                  </div>
                  <div className="content" style={{ padding: 0 }}>
                    <ul>
                      <li>
                        <i className="lni lni-checkmark active"></i>
                        All Features & Capabilities
                      </li>
                      <li>
                        <i className="lni lni-checkmark active"></i>
                        Unlimited Trainees
                      </li>
                      <li>
                        <i className="lni lni-checkmark active"></i>
                        No Extra Costs
                      </li>
                      <li>
                        <i className="lni lni-checkmark active"></i>
                        Email Support
                      </li>
                    </ul>
                  </div>
                  <div className="pricing-btn">
                    <a
                      onClick={subscribeNow}
                      className="main-btn btn-hover"
                      style={{ color: 'white' }}>
                      Subscribe Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PaymentPricingSection;
