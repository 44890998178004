//     <!-- Google tag (gtag.js) -->
//     <script
//       async
//       src="https://www.googletagmanager.com/gtag/js?id=G-SHFNQ2QXMM"
//     ></script>
//     <script>
//       window.dataLayer = window.dataLayer || [];
//       function gtag() {
//         dataLayer.push(arguments);
//       }
//       gtag('js', new Date());

//       gtag('config', 'G-SHFNQ2QXMM');
//     </script>
//     <!-- Hotjar Tracking Code for SubCx -->
//     <script>
//       (function(h, o, t, j, a, r) {
//         h.hj =
//           h.hj ||
//           function() {
//             (h.hj.q = h.hj.q || []).push(arguments);
//           };
//         h._hjSettings = { hjid: 3612993, hjsv: 6 };
//         a = o.getElementsByTagName('head')[0];
//         r = o.createElement('script');
//         r.async = 1;
//         r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
//         a.appendChild(r);
//       })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
//     </script>

const loadGoogleAnalytics = () => {
  const gaScript = document.createElement('script');
  gaScript.async = true;
  gaScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-SHFNQ2QXMM';
  document.head.appendChild(gaScript);

  gaScript.onload = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-SHFNQ2QXMM');
  };
};

const loadHotjar = () => {
  (function(h, o, t, j, a, r) {
    h.hj =
      h.hj ||
      function() {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    h._hjSettings = { hjid: 3612993, hjsv: 6 };
    a = o.getElementsByTagName('head')[0];
    r = o.createElement('script');
    r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
};

export const loadAnalytics = () => {
  loadGoogleAnalytics();
  loadHotjar();
};
