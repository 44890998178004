/* eslint-disable react/react-in-jsx-scope */
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { makeStyles } from '@material-ui/styles';

import DoubleArrowRoundedIcon from '@material-ui/icons/DoubleArrowRounded';
import KeyboardArrowLeftRoundedIcon from '@material-ui/icons/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import CustomCalendar from 'components/CustomCalendar/CustomCalendar';
import { useEffect, useState } from 'react';
import { useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(0),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0)
    }
  },
  iconSize: {
    fontSize: '50px'
  },
  weekDays: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-around',
    flexGrow: 1
  },
  dateBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  dateText: {
    textAlign: 'center',
    cursor: 'pointer',
    margin: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1.5)
    }
  },
  marginLeftAuto: {
    marginLeft: 'auto'
  },
  marginRightAuto: {
    marginRight: 'auto'
  },
  icon: {
    cursor: 'pointer',
    margin: theme.spacing(.5),
  }
}));

const WeekCalendar = props => {
  const {
    weekDays,
    changeDate,
    handlePreviousWeek,
    handleNextWeek,
    handleSelectedDateFromCalendar,
    highlightedDate
  } = props;

  const [calendarOpen, setCalendarOpen] = useState(false);
  const [initialDate, setInitialDate] = useState({});
  const [specificDate, setSpecificDate] = useState(new Date());
  const classes = useStyles();
  const isMobile = !useMediaQuery('(min-width:650px)');
  

  const handleCalendar = weekDays => {
    setInitialDate(initialDate);
    setCalendarOpen(true);
  };

  const handleCalendarClose = () => {
    setCalendarOpen(false);
  };

  return (
    <>
      <div className={classes.root}>
        <Tooltip
          arrow
          data-test="weekCalendar-previousWeekIconButton"
          placement="top"
          title="Previous week">
          <KeyboardArrowLeftRoundedIcon
            data-test={'weekCalendar-previousWeekButton'}
            className={`${classes.icon} ${classes.marginRightAuto}`}
            onClick={handlePreviousWeek}
          />
        </Tooltip>
        <div className={classes.weekDays} data-test="week-list">
          {weekDays.map((day, index) => {
            return (
              <div
                data-test={`${day.dayOfWeek}`}
                className={classes.dateBox}
                key={index}
                onClick={() => changeDate(day)}>
                <div className={classes.dateText}>
                  <Typography
                    style={
                      day.isActive // && day.workoutTemplate && day.workoutTemplate.subGroup === selectedSubgroup
                        ? { color: '#3f51b5' }
                        : { color: 'grey' }
                    }>
                    {isMobile ? day.dayOfWeek[0] : day.dayOfWeek}
                  </Typography>
                  <Typography
                    style={
                      day.isActive // && day.workoutTemplate && day.workoutTemplate.subGroup === selectedSubgroup
                        ? { color: '#3f51b5' }
                        : { color: 'grey' }
                    }>
                    {isMobile ? day.dateObject.getDate() : day.date}
                  </Typography>
                </div>
                {day.isWorkout && day.workoutTemplate ? ( // && day.subGroup === selectedSubgroup // day.workoutTemplate.some(template => template.subGroup === selectedSubgroup)
                  <FiberManualRecordIcon
                    data-test={'weekCalendar-fiberManualRecordIcon'}
                    fontSize={'inherit'}
                    style={
                      day.isActive ? { color: '#3f51b5' } : { color: 'grey' }
                    }
                  />
                ) : (
                  ''
                )}
              </div>
            );
          })}
        </div>
        <Tooltip arrow placement="top" title="Next week">
          <KeyboardArrowRightRoundedIcon
            data-test={'weekCalendar-nextWeekButton'}
            className={`${classes.icon} ${classes.marginLeftAuto}`}
            onClick={handleNextWeek}
          />
        </Tooltip>
        <Tooltip
          arrow
          onClick={() => handleCalendar(weekDays)}
          placement="top"
          title="Open calendar">
          <DoubleArrowRoundedIcon
            className={`${classes.icon} ${classes.marginLeftAuto}`}
            data-test={'weekCalendar-openCalendarButton'}
          />
        </Tooltip>

        {calendarOpen && (
          <CustomCalendar
            handleSelectedDateFromCalendar={handleSelectedDateFromCalendar}
            higlightedDates={highlightedDate}
            onClose={handleCalendarClose}
            open={calendarOpen}
            setSpecificDate={setSpecificDate}
            specificDate={specificDate}
            weekData={weekDays}
          />
        )}
      </div>
    </>
  );
};

export default WeekCalendar;
