import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';

import MobileWorkoutStepTitle from './MobileWorkoutStepTitle';
import MobileWorkoutStepDurationType from './MobileWorkoutStepDurationType';
import MobileWorkoutStepSetDuration from './MobileWorkoutStepSetDuration';
import MobileWorkoutStepDivider from './MobileWorkoutStepDivider';
import MobileWorkoutStepSetPace from './MobileWorkoutStepSetPace';
import MobileWorkoutRepeatStepRepititionsNumber from './MobileWorkoutRepeatStepRepetitionsNumber';

const useStyles = makeStyles(theme => ({
  root: { marginTop: 15 },
  cardBody: {
    padding: 0
  },
  list: {
    width: '100%',
    padding: 0
  },
  listItemPaper: {
    boxShadow: 'none',
    backgroundColor: '#d6d6d6',
    borderRadius: 0,
    padding: '5px 10px',
    fontWeight: 500,
    color: '#5157F8'
  }
}));

const convertSecondsToPace = totalSeconds => {
  if (isNaN(totalSeconds)) return totalSeconds;
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  const paddedSeconds = seconds < 10 ? '0' + seconds : seconds;
  return `${minutes}:${paddedSeconds}`;
};

const convertPaceToSeconds = (minutes, seconds) => minutes * 60 + seconds;

export default function MobileWorkoutRepeatStepCard(props) {
  const { user, step, removeStep, type } = props;
  const classes = useStyles();

  const intenseStep = step.steps[0];
  const recoveryStep = step.steps[1];

  return (
    <Card className={classes.root}>
      <MobileWorkoutStepTitle
        user={user}
        title={'REPEAT STEP'}
        removeStep={removeStep}
        step={step}
        type={type}
      />

      <CardContent className={classes.cardBody}>
        <List className={classes.list}>
          <MobileWorkoutRepeatStepRepititionsNumber
            repetitionsNumber={step.repeatValue}
          />

          <Paper className={classes.listItemPaper}>INTENSE PART</Paper>

          <MobileWorkoutStepDurationType
            durationType={intenseStep.durationType}
          />

          <MobileWorkoutStepDivider />

          <MobileWorkoutStepSetDuration
            durationValue={intenseStep.durationValue}
            durationValueType={intenseStep.durationValueType}
          />

          <MobileWorkoutStepDivider />

          <MobileWorkoutStepSetPace
            pace={convertSecondsToPace(intenseStep.runnerPace)}
          />

          <Paper className={classes.listItemPaper}>RECOVERY PART</Paper>

          <MobileWorkoutStepDurationType
            durationType={recoveryStep.durationType}
          />

          <MobileWorkoutStepDivider />

          <MobileWorkoutStepSetDuration
            durationValue={recoveryStep.durationValue}
            durationValueType={recoveryStep.durationValueType}
          />

          <MobileWorkoutStepDivider />

          <MobileWorkoutStepSetPace
            pace={convertSecondsToPace(recoveryStep.runnerPace)}
          />
        </List>
      </CardContent>
    </Card>
  );
}
