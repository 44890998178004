import React from 'react';
import PropTypes from 'prop-types';
import { normalizePhone, validatePhoneNumber } from '../helpers';
import { Box, TextField } from '@material-ui/core';
import useStyles from '../styles';

const FormHeader = props => {
  const { formState, setFormState, runner, isInvitedByGroupLink } = props;
  const classes = useStyles();

  const handleChange = event => {
    event.persist();

    if (event.target.name === 'mobile') {
      const { value } = event.target;
      event.target.value = normalizePhone(value);
    }

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <Box className={classes.formHeader}>
      <TextField
        className={classes.textField}
        disabled={!!runner.runningGroup || isInvitedByGroupLink}
        error={hasError('runningGroup')}
        fullWidth
        helperText={
          hasError('runningGroup') ? formState.errors.runningGroup[0] : null
        }
        label="Running Group"
        name="runningGroup"
        onChange={handleChange}
        size="medium"
        type="text"
        value={formState.values.runningGroup || ''}
        variant="outlined"
      />
      <TextField
        className={classes.textField}
        error={hasError('firstName')}
        fullWidth
        helperText={
          hasError('firstName') ? formState.errors.firstName[0] : null
        }
        label="First name"
        name="firstName"
        onChange={handleChange}
        type="text"
        value={formState.values.firstName || ''}
        variant="outlined"
      />
      <TextField
        className={classes.textField}
        error={hasError('lastName')}
        fullWidth
        helperText={hasError('lastName') ? formState.errors.lastName[0] : null}
        label="Last name"
        name="lastName"
        onChange={handleChange}
        type="text"
        value={formState.values.lastName || ''}
        variant="outlined"
      />
      <TextField
        className={classes.textField}
        disabled={!!runner.mobile}
        error={
          hasError('mobile') || !validatePhoneNumber(formState.values.mobile)
        }
        fullWidth
        helperText={
          hasError('mobile')
            ? formState.errors.mobile[0]
            : !validatePhoneNumber(formState.values.mobile)
            ? 'Invalid phone number'
            : null
        }
        inputMode="numeric"
        label="Mobile"
        name="mobile"
        onChange={handleChange}
        type="tel"
        value={formState.values.mobile || ''}
        variant="outlined"
      />
      <TextField
        className={classes.textField}
        error={hasError('email')}
        fullWidth
        helperText={hasError('email') ? formState.errors.email[0] : null}
        label="Email address"
        name="email"
        onChange={handleChange}
        type="text"
        value={formState.values.email || ''}
        variant="outlined"
      />
      <TextField
        className={classes.textField}
        error={hasError('password')}
        fullWidth
        helperText={hasError('password') ? formState.errors.password[0] : null}
        label="Password"
        name="password"
        onChange={handleChange}
        type="password"
        value={formState.values.password || ''}
        variant="outlined"
      />
    </Box>
  );
};

export default FormHeader;

FormHeader.propTypes = {
  formState: PropTypes.object,
  setFormState: PropTypes.func
};
