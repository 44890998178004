import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import ImageIcon from '@material-ui/icons/Image';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import SettingsIcon from '@material-ui/icons/Settings';
import LayersIcon from '@material-ui/icons/Layers';
import LockOpenIcon from '@material-ui/icons/LockOpen';

import { ExitToApp, Watch, DirectionsRun } from '@material-ui/icons';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import FiberNewIcon from '@material-ui/icons/FiberNew';

import { Profile, SidebarNav } from './components';
import logOut from 'common/logOut';
import oAuthGarmin from 'common/garminOAuth';

import { SupportRequestModal } from 'components';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

const Sidebar = props => {
  const { open, variant, onClose, className, user, ...rest } = props;

  const classes = useStyles();

  const handleGarminOAuth = event => {
    event.preventDefault(); // Prevent default navigation
    oAuthGarmin(user.email);
  };

  const handleSupportRequest = event => {
    event.preventDefault(); // Prevent default navigation
    handleSupportRequestModalOpen();
  };

  const isTrainee = () => user.role == 'runner';
  const isTrainer = () => user.role == 'trainer';
  const isGarmin = () => user.garminUserId;

  const getPages = () => {
    const traineePages = [
      {
        title: 'Dashboard',
        href: '/dashboard',
        icon: <DashboardIcon />
      },
      {
        title: 'Workout Plan',
        href: '/workoutPlan',
        icon: <DirectionsRun />
      },
      {
        title: 'Garmin Sync',
        href: '//',
        icon: <Watch />,
        onClick: handleGarminOAuth
      },
      {
        title: 'Settings',
        href: '/settings',
        icon: <SettingsIcon />
      },
      {
        title: 'Support',
        href: '//',
        icon: <HelpOutlineOutlinedIcon />,
        onClick: handleSupportRequest
      },
      {
        title: 'Logout',
        href: '/sign-in',
        icon: <ExitToApp />,
        onClick: logOut
      }
    ];

    const trainerPages = [
      {
        title: 'Dashboard',
        href: '/dashboard',
        icon: <DashboardIcon />
      },
      {
        title: 'Runners List',
        href: '/runnersList',
        icon: <PeopleIcon />
      },
      {
        title: 'Create Workout Plan',
        href: '/createWorkoutPlan',
        icon: <FiberNewIcon />
      },
      {
        title: 'Workout Plan',
        href: '/workoutPlan',
        icon: <DirectionsRun />
      },
      {
        title: 'Templates',
        href: '/templates',
        icon: <LayersIcon />
      },
      {
        title: 'Settings',
        href: '/settings',
        icon: <SettingsIcon />
      },
      {
        title: 'Support',
        href: '//',
        icon: <HelpOutlineOutlinedIcon />,
        onClick: handleSupportRequest
      },
      {
        title: 'Logout',
        href: '/sign-in',
        icon: <ExitToApp />,
        onClick: logOut
      }
    ];

    if (isTrainer()) {
      if (isGarmin())
        return trainerPages.filter(item => item.title != 'Garmin Sync');
      return trainerPages;
    }
    if (isTrainee()) {
      if (isGarmin())
        return traineePages.filter(item => item.title != 'Garmin Sync');
      return traineePages;
    }

    return traineePages;
  };

  const pages = getPages();

  const [isSupportRequestModalOpen, setIsSupportRequestModalOpen] = useState(
    false
  );
  const handleSupportRequestModalOpen = () => {
    setIsSupportRequestModalOpen(true);
  };

  const handleSupportRequestModalClose = () => {
    setIsSupportRequestModalOpen(false);
  };

  return (
    <>
      <Drawer
        anchor="left"
        classes={{ paper: classes.drawer }}
        onClose={onClose}
        open={open}
        variant={variant}>
        <div {...rest} className={clsx(classes.root, className)}>
          <Profile user={user} />
          <Divider className={classes.divider} />
          <SidebarNav className={classes.nav} pages={pages} onClose={onClose} />
        </div>
      </Drawer>
      <SupportRequestModal
        key={Date.now().toString()}
        handleSupportRequestModalClose={handleSupportRequestModalClose}
        isSupportRequestModalOpen={isSupportRequestModalOpen}
        user={user}
      />
    </>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
