import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

const RouteWithLayout = props => {
  const {
    layout: Layout,
    component: Component,
    user,
    setUser,
    ...rest
  } = props;

  return (
    <Route
      {...rest}
      render={matchProps => (
        <Layout user={user} setUser={setUser}>
          <Component {...matchProps} user={user} setUser={setUser} />
        </Layout>
      )}
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string
};

export default RouteWithLayout;
