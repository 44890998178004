import React, { useEffect, useRef, useState } from 'react';
import { Map, Marker, Polyline, Popup, TileLayer } from 'react-leaflet';
import startIcon from '../assets/start.png';
import endIcon from '../assets/end.png';
import { icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';

const MapComponent = props => {
  const { samples } = props;

  const [startPosition, setStartPos] = useState();
  const [endPosition, setEndPos] = useState();

  const [bounds, setBounds] = useState();
  const mapRef = useRef(null);


  const [line, setLine] = useState([]);
  const [init, setInit] = useState(false);
  const mapQuality = 3; //the higher the number the lower the quality

  const getColor = (v, min, max) => {
    const getC = (f, l, r) => {
      return {
        r: Math.floor((1 - f) * l.b + f * r.b),
        g: Math.floor((1 - f) * l.g + f * r.g),
        b: Math.floor((1 - f) * l.r + f * r.r)
      };
    };

    var left = { r: 255, g: 0, b: 0 },
      middle = { r: 0, g: 255, b: 0 },
      right = { r: 0, g: 0, b: 255 },
      mid = (max - min) / 2;

    return v < min + mid
      ? getC((v - min) / mid, left, middle)
      : getC((v - min - mid) / mid, middle, right);
  };

  useEffect(() => {
    let locationData = [];

    let maxX = Number.MIN_VALUE;
    let maxY = Number.MIN_VALUE;
    let minX = Number.MAX_VALUE;
    let minY = Number.MAX_VALUE;

    for (let i = 0; i < samples.length; i += mapQuality) {
      const pos1 = samples[i];

      let x1 = pos1.latitudeInDegree;
      let y1 = pos1.longitudeInDegree;

      if (!x1 || !y1) continue;


      if (i >= samples.length - mapQuality) {
        setEndPos([x1, y1]);
        continue;
      }

      const pos2 = samples[i + mapQuality];

      let x2 = pos2.latitudeInDegree;
      let y2 = pos2.longitudeInDegree;
      
      if (!x2 || !y2) continue;

      if (x1 > maxX) maxX = x1;
      if (x1 < minX) minX = x1;
      if (y1 > maxY) maxY = y1;
      if (y1 < minY) minY = y1;

      if (i == 0) {
        setStartPos([x1, y1]);
        setInit(true);
      }
      let avgSpeed =
        (pos2.speedMetersPerSecond + pos1.speedMetersPerSecond) / 2;
      let col = getColor(avgSpeed, 2.3, 3.5);

      locationData.push({
        pos: [
          [x1, y1],
          [x2, y2]
        ],
        color: `rgb(${col.r}, ${col.g}, ${col.b})`
      });
    }
    setBounds([
      [minX, minY],
      [maxX, maxY]
    ]);
    setLine(locationData);
  }, [JSON.stringify(samples[0])]);

  return (
    <div className="map">
      {init && bounds && (
        <Map ref={mapRef} bounds={bounds} scrollWheelZoom={false}>
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <Marker
            icon={icon({
              iconUrl: startIcon,
              iconSize: [30, 30]
              // iconAnchor: [20, 38]
            })}
            zIndexOffset={3}
            position={startPosition}/>
          <Marker
            icon={icon({
              iconUrl: endIcon,
              iconSize: [30, 30]
              // iconAnchor: [20, 38]
            })}
            zIndexOffset={0}
            position={endPosition}/>
          {line.map((v, i) => (
            <Polyline key={i} color={v.color} positions={v.pos} />
          ))}
        </Map>
      )}
      <div className="map-helper">
        Slower <div className="map-gradient"></div> Faster
      </div>
    </div>
  )
};

export default MapComponent;
