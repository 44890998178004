import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import PropTypes from 'prop-types';

import {
  DeleteOutline,
  EditOutlined,
  PauseOutlined,
  PlayArrowOutlined,
  SendRounded
} from '@material-ui/icons';

import Tooltip from '@material-ui/core/Tooltip';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/styles';
import axios from 'axios';
import theme from 'theme';
import CustomDialog from 'components/CustomDialog/CustomDialog';
import RunnerSettingsModal from '../RunnerSettingsModal';

// import MuiAlert from '@material-ui/lab/Alert';
// function Alert(props) {
//   return <MuiAlert elevation={6} variant="filled" {...props} />;
// }

const useStyles = makeStyles({
  rowCell: {
    wordBreak: 'break-word',
    color: runner => (runner.isActive ? '' : 'lightgrey')
  },
  rowCellLast: {
    textAlign: 'end',
    padding: 0
  },
  collapseArrow: {
    textAlign: 'end'
  },
  collapseRow: {
    '& > *': {
      border: 'unset'
    }
  },
  tableCellCollaps: {
    padding: 0
  },
  collapseRowBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: theme.palette.primary.light
  },
  mobileCels: {
    borderBottom: 'none',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  mobileCelsLast: {
    flex: '1',
    textAlign: 'end',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderBottom: '1px'
  },
  iconOnMobile: {
    padding: theme.spacing(1)
  }
});

function Row(props) {
  const {
    user,
    row,
    removeRunnerFromRunnersList,
    setNotificationSeverity,
    setNotificationMessage,
    refresh
  } = props;

  const [runner, setRunner] = useState(row || {});
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const classes = useStyles(runner);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const addZeroInFront = num => {
    if (num == 0) return '00';
    if (num < 10) return '0' + num;
    return num;
  };

  const formatedBasePace =
    runner.basePace?.minutes || runner.basePace.seconds
      ? `${runner.basePace?.minutes || '00'}:${addZeroInFront(
          runner.basePace?.seconds
        ) || '00'}`
      : '-';

  const toggleRunner = async event => {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/toggleRunner`,
      { runnerId: runner.runnerId }
    );
    setRunner(response.data.runner);
  };

  // const [isNotification, setIsNotification] = useState(false);

  const sendInviteToRunner = async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/inviteRunner`,
      { runnerId: runner.runnerId }
    );

    setNotificationSeverity(response.data.severity);
    setNotificationMessage(response.data.msg);
    // setIsNotification(true);
    // setInterval(() => setIsNotification(false), 5000);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleDeleteRunnerOpenModal = () => {
    setOpenDialog(true);
  };

  const handleDeleteRunner = async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_SERVER_URL}/`,
        { data: { runnerId: row.runnerId } }
      );
      removeRunnerFromRunnersList(row.runnerId);
      setNotificationSeverity(response?.data?.severity);
      setNotificationMessage(response?.data?.msg);
    } catch (error) {
      setOpenDialog(false);
      setNotificationSeverity(error?.response?.data?.severity);
      setNotificationMessage(error?.response?.data?.msg);
    }
  };

  return (
    <React.Fragment>
      <TableRow className={classes.row} hover tabIndex={-1}>
        <TableCell className={classes.rowCell} component="th" scope="row">
          {`${runner.firstName} ${runner.lastName}`}
        </TableCell>
        <TableCell className={classes.rowCell}>{runner.subGroup}</TableCell>
        <TableCell className={classes.rowCell}>{formatedBasePace}</TableCell>
        <TableCell className={classes.rowCell}>{runner.runsPerWeek}</TableCell>
        {!isMobile ? (
          <>
            <TableCell className={classes.rowCell}>{runner.mobile}</TableCell>
            <TableCell className={classes.rowCellLast}>
              {runner.isRegistered ? (
                <Tooltip
                  placement="top"
                  title={runner.isActive ? 'Deactivate User' : 'Activate User'}>
                  <IconButton onClick={toggleRunner}>
                    {runner.isActive ? (
                      <PauseOutlined />
                    ) : (
                      <PlayArrowOutlined />
                    )}
                  </IconButton>
                </Tooltip>
              ) : (
                <>
                  <Tooltip placement="top" title={'Send invite to runner'}>
                    <IconButton
                      data-test={
                        'runnersList-components-runnerStable-row-sendInviteIcon'
                      }
                      onClick={sendInviteToRunner}>
                      <SendRounded />
                    </IconButton>
                  </Tooltip>
                </>
              )}
              <Tooltip placement="top" title="Delete Runner">
                <IconButton
                  data-test={
                    'runnersList-components-runnerStable-row-deleteRunnerIcon'
                  }
                  onClick={handleDeleteRunnerOpenModal}>
                  <DeleteOutline />
                </IconButton>
              </Tooltip>
              <Tooltip placement="top" title="Edit Runner">
                <IconButton
                  data-test={
                    'runnersList-components-runnerStable-row-editRunnerIcon'
                  }
                  onClick={handleModalOpen}>
                  <EditOutlined />
                </IconButton>
              </Tooltip>
            </TableCell>
          </>
        ) : (
          <TableCell className={classes.collapseArrow}>
            <IconButton
              aria-label="expand row"
              onClick={() => setOpen(!open)}
              size="small">
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        )}
      </TableRow>
      {isMobile && (
        <TableRow className={classes.collapseRow}>
          <TableCell className={classes.tableCellCollaps} colSpan={5}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box className={classes.collapseRowBox}>
                <Box className={classes.mobileCels}>Mobile</Box>
                <Box className={classes.mobileCels}> {runner.mobile}</Box>
                <Box className={classes.mobileCelsLast}>
                  {runner.isRegistered ? (
                    <Tooltip
                      placement="top"
                      title={
                        runner.isActive ? 'Deactivate User' : 'Activate User'
                      }>
                      <IconButton onClick={toggleRunner}>
                        {runner.isActive ? (
                          <PauseOutlined />
                        ) : (
                          <PlayArrowOutlined />
                        )}
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <>
                      <Tooltip placement="top" title={'Send invite to runner'}>
                        <IconButton onClick={sendInviteToRunner}>
                          <SendRounded />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                  <IconButton
                    className={classes.iconOnMobile}
                    onClick={handleDeleteRunnerOpenModal}>
                    <DeleteOutline />
                  </IconButton>
                  <IconButton
                    className={classes.iconOnMobile}
                    onClick={handleModalOpen}>
                    <EditOutlined />
                  </IconButton>
                </Box>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}

      <RunnerSettingsModal
        handleModalClose={handleModalClose}
        isModalOpen={isModalOpen}
        runner={runner}
        setRunner={setRunner}
        refresh={refresh}
        user={user}
      />

      {/* ------------------ Dialog ---------------------- */}
      <CustomDialog
        onClose={handleCloseDialog}
        onYesClick={handleDeleteRunner}
        open={openDialog}
        title={`Are you sure you want to delete ${runner.firstName} ${runner.lastName}?`}
      />
    </React.Fragment>
  );
}

export default Row;

Row.propTypes = {
  row: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    runningGroup: PropTypes.string.isRequired,
    runsPerWeek: PropTypes.number.isRequired,
    basePace: PropTypes.object.isRequired,
    mobile: PropTypes.string.isRequired
  }).isRequired
};
