import { useState } from 'react';
import axios from 'axios';

import oAuthGarmin from 'common/garminOAuth';

import { initiatedFirebaseApp } from '../../config/firebase-config';
import {
  getAuth,
  createUserWithEmailAndPassword,
  deleteUser
} from 'firebase/auth';

let auth;
if (initiatedFirebaseApp) {
  auth = getAuth();
}

const useRegister = ({ formState, setOpen, history, setUser }) => {
  const [error, setError] = useState({ isError: false, message: '' });

  const createUserInDB = async () => {
    try {
      // eslint-disable-next-line
      const { password, ...userDetails } = formState.values; //remove password from state

      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/register`,
        JSON.stringify({ ...userDetails }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: false
        }
      );

      if (userDetails.role === 'trainer') return response;
      const toSyncGarmin = window.confirm(
        `Hi ${userDetails.firstName}, Do you want to sync your Garmin account?`
      );
      if (toSyncGarmin) return await oAuthGarmin(userDetails.email);

      return response;
    } catch (error) {
      console.error(error);
      setError({
        isError: true,
        message: error?.response?.data?.msg || error.message
      });
      throw new Error(error);
    }
  };

  // const deleteUserFromFirebase = async () => {
  //   const user = auth.currentUser;
  //   try {
  //     return await deleteUser(user);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const handleSignUp = async event => {
    event.preventDefault();
    try {
      const response = await createUserWithEmailAndPassword(
        auth,
        formState.values.email,
        formState.values.password
      );
      if (response) {
        const response = await createUserInDB();
        const activeUntilDate = response?.data?.activeUntilDate
          ? response?.data?.activeUntilDate
          : null;

        setUser({
          ...auth.currentUser,
          ...formState.values,
          _id: response.data._id,
          activeUntilDate
        });
        history.push('/dashboard');
      }
    } catch (error) {
      // await deleteUserFromFirebase();
      setOpen(true);

      if (error.code === 'auth/email-already-in-use') {
        setError({
          isError: true,
          message: 'User already exists. Switch to sign-in page...'
        });
      } else {
        setError({ isError: true, message: error.message || error.code });
        console.log(error.message);
      }
    }
  };

  return { handleSignUp, error };
};

export default useRegister;
