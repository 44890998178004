/* eslint-disable react/no-multi-comp */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import EnhancedTableToolbar from '../EnhancedTableToolbar/EnhancedTableToolbar';

import WorkoutAnalysisModal from '../WorkoutAnalysis/WorkoutAnalysisModal';

function descendingComparator(a, b, orderBy) {
  if (!(b[orderBy] || b.weekRuns[orderBy]?.distanceCovered)) return -1;
  if (!(a[orderBy] || a.weekRuns[orderBy]?.distanceCovered)) return 1;
  if (
    b[orderBy] < a[orderBy] ||
    b.weekRuns[orderBy]?.distanceCovered < a.weekRuns[orderBy]?.distanceCovered
  ) {
    return -1;
  }
  if (
    b[orderBy] > a[orderBy] ||
    b.weekRuns[orderBy]?.distanceCovered > a.weekRuns[orderBy]?.distanceCovered
  ) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const convertEpochToStartEndOfWeek = epoch => {
  const epochDate = new Date(epoch);
  const firstDayOfWeek = epochDate.getDate() - epochDate.getDay();
  // First day is the day of the month - the day of the week
  const weekStartDate = new Date(epochDate.setDate(firstDayOfWeek));
  const weekEndDate = new Date(epochDate.setDate(weekStartDate.getDate() + 6));
  const startOfWeek = weekStartDate.setHours(0, 0, 0, 0);
  const endOfWeek = weekEndDate.setHours(23, 59, 59, 999);
  return [startOfWeek, endOfWeek];
};

const getDatesInRange = (startDate, endDate) => {
  const date = new Date(startDate);
  const dates = [];
  while (date <= endDate) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return dates;
};

const convertDatesObjectsToDDMM = datesArray => {
  return [
    '',
    ...datesArray.map(date => `${date.getDate()}/${date.getMonth() + 1}`)
  ];
};

const headCells = [
  { id: 'name', label: 'Name' },
  { id: 'sunday', label: 'Sunday' },
  { id: 'monday', label: 'Monday' },
  { id: 'tuesday', label: 'Tuesday' },
  { id: 'wednesday', label: 'Wednesday' },
  { id: 'thursday', label: 'Thursday' },
  { id: 'friday', label: 'Friday' },
  { id: 'saturday', label: 'Saturday' },
  { id: 'weekTotal', label: 'Total Distance' }
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort, date } = props;
  const [weekDates, setWeekDates] = useState([]);
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  useEffect(() => {
    const [startOfWeek, endOfWeek] = convertEpochToStartEndOfWeek(date);
    const _weekDates = getDatesInRange(startOfWeek, endOfWeek);
    const labelWeekDates = convertDatesObjectsToDDMM(_weekDates);
    setWeekDates(labelWeekDates);
  }, [date]);

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            align="center"
            key={headCell.id}
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}>
              <div>
                {headCell.label}
                {/* <br /> */}
                <Typography fontWeight="fontWeightLight">
                  {weekDates[index]}
                </Typography>
              </div>
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: theme.spacing(2)
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  clickable: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));

const WeekView = props => {
  const { user } = props;
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const [date, setDate] = React.useState(new Date());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dayData, setDayData] = useState(null);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const getWorkoutAnalysis = data => {
    setDayData(data);
    handleModalOpen();
  };

  const getWeekActivitiesByDate = async date => {
    const epochDate = date.getTime();
    const payload =
      user.role == 'trainer'
        ? { trainerUserId: user._id, epochDate }
        : { runnerId: user._id, epochDate };
    const weekActivities = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/activity/weekActivities`,
      payload
    );
    setRows(weekActivities.data);
  };

  useEffect(() => {
    getWeekActivitiesByDate(date);
  }, [date]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event, name) => {
    console.log(event, name);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <EnhancedTableToolbar
            setDate={setDate}
            numSelected={selected.length}
          />
          <TableContainer>
            <Table
              aria-label="enhanced table"
              aria-labelledby="tableTitle"
              className={classes.table}>
              <EnhancedTableHead
                classes={classes}
                date={date}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                order={order}
                orderBy={orderBy}
                rowCount={rows.length}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        aria-checked={isItemSelected}
                        hover
                        key={row.name}
                        selected={isItemSelected}
                        tabIndex={-1}>
                        <TableCell component="th" id={labelId} scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell
                          className={
                            row.weekRuns?.sunday?.distanceCovered
                              ? classes.clickable
                              : ''
                          }
                          onClick={() =>
                            row.weekRuns?.sunday
                              ? getWorkoutAnalysis({
                                  runnerName: row.name,
                                  ...row.weekRuns?.sunday
                                })
                              : null
                          }
                          align="center">
                          {row.weekRuns?.sunday?.distanceCovered || '-'}
                        </TableCell>
                        <TableCell
                          className={
                            row.weekRuns?.monday?.distanceCovered
                              ? classes.clickable
                              : ''
                          }
                          onClick={() =>
                            row.weekRuns?.monday
                              ? getWorkoutAnalysis({
                                  runnerName: row.name,
                                  ...row.weekRuns?.monday
                                })
                              : null
                          }
                          align="center">
                          {row.weekRuns?.monday?.distanceCovered || '-'}
                        </TableCell>
                        <TableCell
                          className={
                            row.weekRuns?.tuesday?.distanceCovered
                              ? classes.clickable
                              : ''
                          }
                          onClick={() =>
                            row.weekRuns?.tuesday
                              ? getWorkoutAnalysis({
                                  runnerName: row.name,
                                  ...row.weekRuns?.tuesday
                                })
                              : null
                          }
                          align="center">
                          {row.weekRuns?.tuesday?.distanceCovered || '-'}
                        </TableCell>
                        <TableCell
                          className={
                            row.weekRuns?.wednesday?.distanceCovered
                              ? classes.clickable
                              : ''
                          }
                          onClick={() =>
                            row.weekRuns?.wednesday
                              ? getWorkoutAnalysis({
                                  runnerName: row.name,
                                  ...row.weekRuns?.wednesday
                                })
                              : null
                          }
                          align="center">
                          {row.weekRuns?.wednesday?.distanceCovered || '-'}
                        </TableCell>
                        <TableCell
                          className={
                            row.weekRuns?.thursday?.distanceCovered
                              ? classes.clickable
                              : ''
                          }
                          onClick={() =>
                            row.weekRuns?.thursday
                              ? getWorkoutAnalysis({
                                  runnerName: row.name,
                                  ...row.weekRuns?.thursday
                                })
                              : null
                          }
                          align="center">
                          {row.weekRuns?.thursday?.distanceCovered || '-'}
                        </TableCell>
                        <TableCell
                          className={
                            row.weekRuns?.friday?.distanceCovered
                              ? classes.clickable
                              : ''
                          }
                          onClick={() =>
                            row.weekRuns?.friday
                              ? getWorkoutAnalysis({
                                  runnerName: row.name,
                                  ...row.weekRuns?.friday
                                })
                              : null
                          }
                          align="center">
                          {row.weekRuns?.friday?.distanceCovered || '-'}
                        </TableCell>
                        <TableCell
                          className={
                            row.weekRuns?.saturday?.distanceCovered
                              ? classes.clickable
                              : ''
                          }
                          onClick={() =>
                            row.weekRuns?.saturday
                              ? getWorkoutAnalysis({
                                  runnerName: row.name,
                                  ...row.weekRuns?.saturday
                                })
                              : null
                          }
                          align="center">
                          {row.weekRuns?.saturday?.distanceCovered || '-'}
                        </TableCell>
                        <TableCell align="center">{row.weekTotal}</TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={rows.length}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </Paper>
      </div>

      {dayData && (
        <WorkoutAnalysisModal
          key={Date.now().toString()}
          handleModalClose={handleModalClose}
          isModalOpen={isModalOpen}
          user={user}
          dayData={dayData}
        />
      )}
    </>
  );
};

export default WeekView;
