import React, { useState, useEffect } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import schema from './schema';
import { Grid, Typography, Snackbar } from '@material-ui/core';
import useStyles from './styles';
import axios from 'api/axios';
import Alert from 'components/Alert';

import FormHeader from './components/FormHeader';
import FormFooter from './components/FormFooter/FormFooter';
import SelectRole from './components/FormFooter/SelectRole';
import useRegister from './useRegister';

const SignUp = props => {
  const { history, setUser } = props;
  const classes = useStyles();
  const [isInvitedByGroupLink, setIsInvitedByGroupLink] = useState(false)
  const [runner, setRunner] = useState({});

  const { runnerId } = useParams();

  useEffect(() => {
    if (runnerId) {
      axios
        .post(`${process.env.REACT_APP_SERVER_URL}/getRunner`, { runnerId })
        .then(res => {
          const runnerInfo = res.data.runner;
          if (runnerInfo) {
            if (runnerInfo.role == 'trainer') {
              setIsInvitedByGroupLink(true)
              setFormState({
                ...formState,
                values: {
                  ...formState.values,
                  runnerId: null,
                  trainerUserId: runnerId,
                  runningGroup: runnerInfo.runningGroup,
                  firstName: '',
                  lastName: '',
                  mobile: '',
                  role: 'runner'
                }
              })
              return
            }
            setRunner(runnerInfo);
            setFormState({
              ...formState,
              values: {
                ...formState.values,
                runnerId: runnerInfo._id,
                runningGroup: runnerInfo.runningGroup,
                firstName: runnerInfo.firstName,
                lastName: runnerInfo.lastName,
                mobile: runnerInfo.mobile,
                role: 'runner'
              }
            });
          }
        });
    }
  }, []);
  
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const { handleSignUp, error } = useRegister({
    formState,
    setOpen,
    history,
    setUser
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.content} item lg={7} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <Typography variant="h1">Sign Up</Typography>
            </div>
            <div className={classes.contentBody}>
              <form className={classes.form} onSubmit={handleSignUp}>
                <SelectRole
                  formState={formState}
                  setFormState={setFormState}
                  runner={runner}
                  isInvitedByGroupLink={isInvitedByGroupLink}
                />
                <FormHeader
                  formState={formState}
                  setFormState={setFormState}
                  runner={runner}
                  isInvitedByGroupLink={isInvitedByGroupLink}
                />
                <FormFooter
                  formState={formState}
                  setFormState={setFormState}
                  runner={runner}
                />
              </form>
            </div>
          </div>
        </Grid>
        <Grid className={classes.imgContainer} item lg={5}>
          <div className={classes.img} />
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        autoHideDuration={4000}
        onClose={handleClose}
        open={open}>
        <Alert onClose={handleClose} severity="error">
          {error.isError
            ? error.message || 'Something went wrong..'
            : 'User already exists. Switch to sign-in page...'}
        </Alert>
      </Snackbar>
    </div>
  );
};

SignUp.propTypes = {
  history: PropTypes.object
};

export default withRouter(SignUp);
