import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
import PaymentPricingSection from './PaymentPricingSection/PaymentPricingSection';
import logOut from 'common/logOut';
import { SupportRequestModal } from '../../components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    paddingTop: '10%',
    textAlign: 'center'
  }
}));

const Payment = props => {
  const { user } = props;
  const classes = useStyles();

  const [isSupportRequestModalOpen, setIsSupportRequestModalOpen] = useState(
    false
  );
  const handleSupportRequestModalOpen = () => {
    setIsSupportRequestModalOpen(true);
  };

  const handleSupportRequestModalClose = () => {
    setIsSupportRequestModalOpen(false);
  };

  return (
    <>
      <div className={classes.root}>
        <Grid container justifyContent="center" spacing={4}>
          <Grid item lg={6} xs={12}>
            <div
              className={classes.content}
              style={{
                textAlign: 'left',
                paddingLeft: '5%',
                paddingRight: '5%'
              }}>
              <div style={{ marginBottom: '5%' }}>
                <Typography variant="h1" style={{ fontFamily: 'Arimo' }}>
                  Hi {user?.firstName},
                </Typography>
              </div>
              <div style={{ marginBottom: '5%' }}>
                <Typography variant="h2" style={{ fontFamily: 'Arimo' }}>
                  Unfortunately, your eligibility period has expired and it's
                  time to renew your account. <br /> <br />
                  Please choose a monthly or yearly plan and click the Subscribe
                  Now button.
                </Typography>
              </div>

              <Typography variant="h4" style={{ fontFamily: 'Arimo' }}>
                Feel free to&nbsp;
                <a
                  href="#"
                  onClick={handleSupportRequestModalOpen}
                  style={{ color: '#90caf9' }}>
                  contact us
                </a>
                &nbsp;with any questions.
              </Typography>

              <Typography variant="h4" style={{ fontFamily: 'Arimo' }}>
                if you wish to log out, click&nbsp;
                <a href="#" onClick={logOut} style={{ color: '#90caf9' }}>
                  here.
                </a>
              </Typography>
            </div>
          </Grid>
          <Grid item lg={6} xs={12}>
            <PaymentPricingSection user={user} />
            {/* <div className={classes.content}>
            <Typography variant="h1">
              This is the right side of the payment page
            </Typography>
          </div> */}
          </Grid>
        </Grid>
      </div>
      <SupportRequestModal
        key={Date.now().toString()}
        handleSupportRequestModalClose={handleSupportRequestModalClose}
        isSupportRequestModalOpen={isSupportRequestModalOpen}
        user={user}
      />
    </>
  );
};

export default Payment;
