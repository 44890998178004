import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  Typography
} from '@material-ui/core';
import axios from 'axios';
import { CustomDialog, SnackbarNotification } from '../../../../components';

const useStyles = makeStyles(() => ({
  root: {}
}));

const Unsubscribe = props => {
  const { className, user, setSubscriptionID, ...rest } = props;
  const classes = useStyles();
  
  const [openCustomDialog, setOpenCustomDialog] = useState(false);

  const [isNotification, setIsNotification] = React.useState(false);
  const [notificationSeverity, setNotificationSeverity] = React.useState('');
  const [notificationMessage, setNotificationMessage] = React.useState('');

  const displayNotification = (severity, message) => {
    setNotificationSeverity(severity);
    setNotificationMessage(message);
    setIsNotification(true);
  };

  const onOpenCustomDialog = () => {
    setOpenCustomDialog(true);
  };

  const onCloseCustomDialog = () => {
    setOpenCustomDialog(false);
  };

  const onYesClickCustomDialog = async () => {
    setOpenCustomDialog(false);
    const response = await unsubscribeTrainer();
    displayNotification(response.data.severity, response.data.msg);
    setSubscriptionID('');
  };

  const unsubscribeTrainer = () => {
    const response = axios.post(
      `${process.env.REACT_APP_SERVER_URL}/payment/unsubscribe`,
      {
        userId: user._id
      }
    );
    return response;
  };

  return (
    <>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardHeader subheader="Unsubscribe from Sub-X" title="Unsubscribe" />
        <Divider />
        <CardContent>
          <Typography variant="body1">
            If you want to, you can unsubscribe from Sub-X here, but we would be
            very sad to see you go.
          </Typography>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            onClick={onOpenCustomDialog}
            variant="outlined">
            Unsubscribe
          </Button>
        </CardActions>
      </Card>
      <CustomDialog
        title={'Are you sure you want to unsubscribe from Sub-X?'}
        open={openCustomDialog}
        onClose={onCloseCustomDialog}
        onYesClick={onYesClickCustomDialog}
      />
      <SnackbarNotification
        isNotificationDisplayed={isNotification}
        setIsNotificationDisplayed={setIsNotification}
        message={notificationMessage}
        severity={notificationSeverity}
      />
    </>
  );
};

Unsubscribe.propTypes = {
  className: PropTypes.string
};

export default Unsubscribe;
