import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CardHeader from '@material-ui/core/CardHeader';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { Step } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  cardHeader: {
    padding: '5px 10px',
    backgroundColor: '#d6d6d6'
  },
  cardHeaderTitle: {
    fontWeight: 500,
    fontSize: 14,
    color: 'blue'
  },
  cardHeaderButton: {
    padding: '0 5px',
    marginTop: 7,
    color: '#3d3d3d'
  }
}));

export default function MobileWorkoutStepTitle(props) {
  const { user, title, removeStep, step, type } = props;
  const classes = useStyles();

  return (
    <CardHeader
      className={classes.cardHeader}
      avatar={
        <strong>
          <Typography className={classes.cardHeaderTitle}>{title}</Typography>
        </strong>
      }
      action={
        type == 'WeeklyPlanPreviewModal' && user.role == 'trainer' ? (
          <IconButton
            aria-label="settings"
            className={classes.cardHeaderButton}
            onClick={() => removeStep(step)}>
            <DeleteOutlinedIcon />
          </IconButton>
        ) : (
          <></>
        )
      }
    />
  );
}
