import React, { useEffect, useState } from 'react';
import { Redirect, Switch } from 'react-router-dom';

import ScrollToTop from './common/ScrollToTop';
import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  AccountView,
  CreateWorkoutPlanView,
  DashboardView,
  HomeView,
  IconsView,
  NotFoundView,
  PrivacyView,
  ProductListView,
  RecoverPasswordView,
  RunnersListView,
  SettingsView,
  SignInView,
  SignUpView,
  TemplateDashboardView,
  TypographyView,
  UserListView,
  WorkoutPlanView,
  TemplatesView,
  PaymentView
} from './views';

import axios from 'axios';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Box, CircularProgress } from '@material-ui/core';

const getUserDataFromDB = async userEmail => {
  if (!userEmail) return null;
  const userDataFromDB = await axios.post(
    `${process.env.REACT_APP_SERVER_URL}/isUser`,
    { userEmail }
  );
  return userDataFromDB.data;
};

const Routes = () => {
  const [user, setUser] = useState(null);
  const [userLoaded, setUserLoaded] = useState(false)
  useEffect(() => {
    const setupUser = async () => {
      const auth = getAuth();
      onAuthStateChanged(auth, loggedUser => {
        if (loggedUser) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          // const uid = user.uid;
          getUserDataFromDB(loggedUser.email).then(userDataFromDB => {
            setUser({ ...loggedUser, ...userDataFromDB });
            setUserLoaded(true)
          });
        } else {
          setUser(null);
          setUserLoaded(true)
        }
      });
    };

    setupUser();

    return () => {};
  }, []);

  if (user) {
    if (user.role === 'trainer') {
      if (
        !user.activeUntilDate ||
        new Date(user.activeUntilDate) < new Date()
      ) {
        return (
          <ScrollToTop>
            <Switch>
              <RouteWithLayout
                component={PaymentView}
                exact
                layout={MinimalLayout}
                path="/payment"
                user={user}
              />
              <Redirect to="/payment" />
            </Switch>
          </ScrollToTop>
        );
      }
    }

    return (
      <ScrollToTop>
        <Switch>
          <Redirect exact from="/" to="/workoutPlan" />
          <Redirect exact from="/sign-in" to="/workoutPlan" />
          <RouteWithLayout
            component={DashboardView}
            exact
            layout={MainLayout}
            path="/dashboard"
            user={user}
          />
          <RouteWithLayout
            component={RunnersListView}
            exact
            layout={MainLayout}
            path="/runnersList"
            user={user}
          />
          <RouteWithLayout
            component={CreateWorkoutPlanView}
            exact
            layout={MainLayout}
            path="/createWorkoutPlan"
            user={user}
          />
          <RouteWithLayout
            component={WorkoutPlanView}
            exact
            layout={MainLayout}
            path="/workoutPlan"
            user={user}
          />
          <RouteWithLayout
            component={TemplatesView}
            exact
            layout={MainLayout}
            path="/templates"
            user={user}
          />
          <RouteWithLayout
            component={TemplateDashboardView}
            exact
            layout={MainLayout}
            path="/templateDashboard"
            user={user}
          />
          <RouteWithLayout
            component={UserListView}
            exact
            layout={MainLayout}
            path="/users"
            user={user}
          />
          <RouteWithLayout
            component={ProductListView}
            exact
            layout={MainLayout}
            path="/products"
            user={user}
          />
          <RouteWithLayout
            component={TypographyView}
            exact
            layout={MainLayout}
            path="/typography"
            user={user}
          />
          <RouteWithLayout
            component={IconsView}
            exact
            layout={MainLayout}
            path="/icons"
            user={user}
          />
          <RouteWithLayout
            component={AccountView}
            exact
            layout={MainLayout}
            path="/account"
            user={user}
          />
          <RouteWithLayout
            component={SettingsView}
            exact
            layout={MainLayout}
            path="/settings"
            user={user}
          />
          <RouteWithLayout
            component={SignInView}
            exact
            layout={MinimalLayout}
            path="/sign-in"
            setUser={setUser}
          />
          <RouteWithLayout
            component={RecoverPasswordView}
            exact
            layout={MinimalLayout}
            path="/recover-password"
          />
          <RouteWithLayout
            component={SignUpView}
            exact
            layout={MinimalLayout}
            path="/sign-up/:runnerId"
            setUser={setUser}
          />
          <RouteWithLayout
            component={SignUpView}
            exact
            layout={MinimalLayout}
            path="/sign-up"
            setUser={setUser}
          />
          <RouteWithLayout
            component={NotFoundView}
            exact
            layout={MinimalLayout}
            path="/not-found"
          />
          <Redirect to="/not-found" />
        </Switch>
      </ScrollToTop>
    );
  }
  else if (!userLoaded) {
    return <Box
      sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        flexDirection: 'column', 
        gap: '20px',
        height: '100vh' }}
    >
      <img src="images/logos/subcx_logo_blue.png"/>
      <CircularProgress/>
    </Box>
  } 
  else {
    return (
      <ScrollToTop>
        <Switch>
          <HomeView exact path="/" />
          <RouteWithLayout
            component={SignInView}
            exact
            layout={MinimalLayout}
            path="/sign-in"
            setUser={setUser}
          />
          <RouteWithLayout
            component={RecoverPasswordView}
            exact
            layout={MinimalLayout}
            path="/recover-password"
          />
          <RouteWithLayout
            component={SignUpView}
            exact
            layout={MinimalLayout}
            path="/sign-up/:runnerId"
            setUser={setUser}
          />
          <RouteWithLayout
            component={SignUpView}
            exact
            layout={MinimalLayout}
            path="/sign-up"
            setUser={setUser}
          />
          <RouteWithLayout
            component={PrivacyView}
            exact
            layout={MinimalLayout}
            path="/privacy"
          />
          <RouteWithLayout
            component={NotFoundView}
            exact
            layout={MinimalLayout}
            path="/not-found"
          />
          <Redirect to="/" />
        </Switch>
      </ScrollToTop>
    );
  }
};

export default Routes;
