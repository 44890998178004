import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import MobileWorkoutStepTitle from './MobileWorkoutStepTitle';
import MobileWorkoutStepDurationType from './MobileWorkoutStepDurationType';
import MobileWorkoutStepSetDuration from './MobileWorkoutStepSetDuration';
import MobileWorkoutStepDivider from './MobileWorkoutStepDivider';
import MobileWorkoutStepSetPace from './MobileWorkoutStepSetPace';

const useStyles = makeStyles(theme => ({
  root: { marginTop: 15 },
  cardBody: {
    padding: 0
  },
  list: {
    width: '100%',
    padding: 0
  }
}));

const convertSecondsToPace = totalSeconds => {
  if (isNaN(totalSeconds)) return totalSeconds;
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  const paddedSeconds = seconds < 10 ? '0' + seconds : seconds;
  return `${minutes}:${paddedSeconds}`;
};

const convertPaceToSeconds = (minutes, seconds) => minutes * 60 + seconds;

export default function MobileWorkoutStepCard(props) {
  const { user, step, removeStep, type } = props;
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <MobileWorkoutStepTitle
        user={user}
        title={'WORKOUT STEP'}
        removeStep={removeStep}
        step={step}
        type={type}
      />
      <CardContent className={classes.cardBody}>
        <List className={classes.list}>
          <MobileWorkoutStepDurationType durationType={step.durationType} />
          <MobileWorkoutStepDivider />
          <MobileWorkoutStepSetDuration
            durationValue={step.durationValue}
            durationValueType={step.durationValueType}
          />
          <MobileWorkoutStepDivider />
          <MobileWorkoutStepSetPace
            pace={convertSecondsToPace(step.runnerPace)}
          />
        </List>
      </CardContent>
    </Card>
  );
}
