import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MonthView from './components/MonthView/MonthView';
import WeekView from './components/WeekView/WeekView';

const Dashboard = props => {
  const { user } = props;

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Paper square>
        <Tabs
          indicatorColor="primary"
          onChange={handleChange}
          textColor="primary"
          value={value}>
          <Tab label="Weekly View" />
          <Tab label="Monthly View" />
        </Tabs>
      </Paper>
      {value ? <Grid container justifyContent="center"><MonthView user={user} /></Grid> : <WeekView user={user} />}
    </>
  );
};

export default Dashboard;
