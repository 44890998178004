import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Paper, Tab, Tabs } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import MonthView from './components/MonthView/MonthView';

import axios from 'axios';

import MainWeeklyWorkoutPlan from './components/MainWeeklyWorkoutPlan';

const useStyles = makeStyles(theme => ({
  root: {
    // margin: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      // padding: theme.spacing(2)
      margin: theme.spacing(2)
    }
  }
}));

export function getDayOfWeekAndDate(date) {
  if (!date) return { dayOfWeek: '', formattedDate: '' };
  const dateObject = new Date(date);
  const daysOfWeek = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ];
  const dayIndex = dateObject.getDay();
  const dayOfWeek = daysOfWeek[dayIndex];

  const day = String(dateObject.getDate()).padStart(2, '0');
  const month = String(dateObject.getMonth() + 1).padStart(2, '0');
  const formattedDate = `${day}/${month}`;

  return {
    dayOfWeek,
    formattedDate
  };
}

function getCompleteWeekWorkouts(runnerId, trainerUserId, workouts, date) {
  // const runnerId = workouts[0]?.runnerId;
  // const trainerUserId = workouts[0]?.trainerUserId;

  // Create an array to hold the completed week's workouts
  const completedWeek = [];

  // Create a date object for the current date
  // const currentDate = new Date();

  // Find the start date (Sunday) of the current week
  const startDate = new Date(date);
  startDate.setDate(startDate.getDate() - startDate.getDay());

  // Iterate over the days of the week (Sunday to Saturday)
  for (let i = 0; i < 7; i++) {
    // Create a date object for the current day
    const currentDate = new Date(startDate);
    currentDate.setDate(currentDate.getDate() + i);

    // Find the workout for the current day
    const workout = workouts.find(w => {
      const workoutDate = new Date(w.dateObject);
      return (
        workoutDate.getFullYear() === currentDate.getFullYear() &&
        workoutDate.getMonth() === currentDate.getMonth() &&
        workoutDate.getDate() === currentDate.getDate()
      );
    });

    // Check if a workout exists for the current day
    if (workout) {
      const { dayOfWeek, formattedDate } = getDayOfWeekAndDate(
        workout.dateObject
      );
      // Add the workout to the completed week array
      completedWeek.push({
        ...workout,
        isWorkout: true,
        dayOfWeek,
        date: formattedDate
      });
    } else {
      const { dayOfWeek, formattedDate } = getDayOfWeekAndDate(currentDate);
      // Add a placeholder workout object for the current day
      const placeholderWorkout = {
        runnerId,
        trainerUserId,
        dateObject: currentDate.toISOString(),
        isWorkout: false,
        dayOfWeek,
        date: formattedDate
      };
      completedWeek.push(placeholderWorkout);
    }
  }
  return completedWeek;
}

const isItCurrentlyAfterSaturdayAt1600 = date => {
  var currentDate = new Date(date);
  var nextWeekBegin = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() + (7 - currentDate.getDay())
  );
  return nextWeekBegin - currentDate < 8 * 60 * 60 * 1000;
};

const WorkoutPlan = props => {
  const { user } = props;
  const classes = useStyles();
  const [date, setDate] = React.useState(
    isItCurrentlyAfterSaturdayAt1600(new Date())
      ? new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
      : new Date()
  );
  const [runnersWeeklyWorkoutsPlan, setRunnersWeeklyWorkoutsPlan] = useState(
    []
  );

  const updateWeekView = () => {
    const getRunnersWeeklyWorkoutsPlan = async () => {
      const payload =
        user.role == 'trainer'
          ? { trainerUserId: user._id, isActive: true, date }
          : { runnerId: user._id, isActive: true, date };
      const runnersWeeklyWorkoutsPlanFromDB = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/workout/runnersWeeklyWorkoutPlan`,
        payload
      );

      return runnersWeeklyWorkoutsPlanFromDB.data.sortedWorkouts;
    };
    setRunnersWeeklyWorkoutsPlan([]); // Reset state before API call
    getRunnersWeeklyWorkoutsPlan()
      .then(runnersWeeklyWorkoutsPlan =>
        runnersWeeklyWorkoutsPlan.map(runner => {
          const completeWorkoutsWeek = getCompleteWeekWorkouts(
            runner.runnerId,
            runner.trainerUserId,
            runner.workouts,
            date
          );
          return { ...runner, workouts: completeWorkoutsWeek };
        })
      )
      .then(res => {
        setRunnersWeeklyWorkoutsPlan(res);
      });
  }

  useEffect(updateWeekView, [date]);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (<>
    <Paper square>
      <Tabs
        indicatorColor="primary"
        onChange={handleChange}
        textColor="primary"
        value={value}>
        <Tab label="Weekly View" />
        <Tab label="Monthly View" />
      </Tabs>
    </Paper>
    {value ?
      <Grid container justifyContent="center">
        <MonthView
          user={user}
          updateWeekView={updateWeekView}
        />
      </Grid> :
      <Paper className={classes.root} square>
        <CssBaseline />
        <MainWeeklyWorkoutPlan
          weeklyWorkoutPlan={runnersWeeklyWorkoutsPlan}
          user={user}
          setDate={setDate}
          updateWeekView={updateWeekView}
        />
      </Paper>}
    </>
  );
};

export default WorkoutPlan;
