import React from 'react';
import PropTypes from 'prop-types';
import { validatePhoneNumber } from '../../helpers';
import useStyles from '../../styles';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Typography, Button, Link } from '@material-ui/core';

const FormFooter = ({ formState, setFormState, runner }) => {
  const classes = useStyles();

  return (
    <Box className={classes.formFooter}>
      <Button
        className={classes.signUpButton}
        color="primary"
        disabled={
          !(
            formState.isValid &&
            formState.values.role &&
            validatePhoneNumber(formState.values.mobile)
          )
        }
        fullWidth
        size="large"
        type="submit"
        variant="contained">
        Create Account
      </Button>
      <Typography color="textSecondary" variant="body1">
        Already have an account?
        <Link component={RouterLink} to="/sign-in" variant="h6">
          Log in
        </Link>
      </Typography>
    </Box>
  );
};

export default FormFooter;

FormFooter.propTypes = {
  formState: PropTypes.object,
  setFormState: PropTypes.func
};
