/* eslint-disable react/no-multi-comp */
import React, { useRef, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import WorkoutChangeModal from './WorkoutChangeModal/WorkoutChangeModal';

import WatchSyncIcon from './WatchSyncIcon/WatchSyncIcon';
import { useEffect } from 'react';
// import tippy from 'tippy.js';
// import 'tippy.js/themes/light.css'
import { Tooltip } from '@material-ui/core';


export const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[4],
    fontSize: 12,
    margin: '4px 0',
    maxWidth: 500
  },
}))(Tooltip);

const StyledTableCell = withStyles(() => ({
  head: {
    // backgroundColor: '#a3a3a3',
    // color: theme.palette.common.white,
  },
  body: {
    backgroundColor: '#d9d9d9',
    border: 0,
    fontSize: 15
    // padding: 5
  }
}))(TableCell);

const useStyles = makeStyles(() => ({
  greyPaper: {
    backgroundColor: '#d9d9d9',
    boxShadow: 'none',
    marginTop: 5,
    paddingLeft: 3,
    paddingRight: 3
  },
  greyPaperbox: {
    display: 'flex',
    justifyContent: 'center',
    '& > *': {
      width: 100
    }
  },
  transparentPaperBox: {
    backgroundColor: 'transparent',
    boxShadow: 'none'
  },
  greyColor: {
    color: '#a3a3a3'
  },
  tblHeadingLeft: {
    textAlign: 'center',
    fontWeight: 500,
    color: '#a3a3a3',
    backgroundColor: '#efefef'
  },
  greyBgCol: {
    backgroundColor: '#d9d9d9',
    borderTop: 'none !important'
  },
  tblRow: {
    '& > *': {
      padding: 4
    }
  },
  rowHoverTrainerToEditStyle: {
    '& > *': {
      padding: 4
    },
    cursor: 'pointer',
    boxShadow: '0 0 0 2px lightblue'
  },

  rowHoverRunnerToSyncStyle: {
    '& > *': {
      padding: 4
    },
    position: 'relative',
    cursor: 'pointer'
  },

  hoverRunnerToSync: {
    backgroundColor: 'rgba(217, 217, 217, 0.8)',
    width: '100%',
    height: '100%',
    fontSize: 24,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#5157F8'
  },
  roundStartCorners: {
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4
  },
  roundEndCorners: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4
  }
}));

const weekdays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

const convertSecondsToPace = totalSeconds => {
  if (isNaN(totalSeconds)) return totalSeconds;
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  const paddedSeconds = seconds < 10 ? '0' + seconds : seconds;
  return `${minutes}:${paddedSeconds}`;
};
const getDurationValueType = durationValueType => {
  if (durationValueType == 'KILOMETER') return 'km';
  if (durationValueType == 'METER') return 'm';
  if (durationValueType == 'MINUTE') return 'min';
};
export const getWarmupDuration = workoutSteps => {
  const warmupStep = workoutSteps?.find(step => step.intensity == 'WARMUP');
  if (!warmupStep) return '';
  let warmupStepDuration =
    warmupStep.durationValue +
    getDurationValueType(warmupStep.durationValueType);
  if (warmupStep.durationType == 'OPEN')
    warmupStepDuration = 'Until Lap Press';
  return warmupStepDuration;
};

export const getCooldownDuration = workoutSteps => {
  const cooldownStep = workoutSteps?.find(
    step => step.intensity == 'COOLDOWN'
  );
  if (!cooldownStep) return '';
  if (cooldownStep.durationType == 'OPEN') return 'Until Lap Press';
  let cooldownStepDuration =
    cooldownStep.durationValue +
    getDurationValueType(cooldownStep.durationValueType);
  return cooldownStepDuration;
};



export const MainPart = ({workoutSteps, CellComponent=StyledTableCell}) => {
  const classes = useStyles();
  const HRZones = [
    'Zone 1 (Warm-Up)',
    'Zone 2 (Easy)',
    'Zone 3 (Aerobic)',
    'Zone 4 (Threshold)',
    'Zone 5 (Maximum)'
  ];
  const getStepDuration = step => {
    if (step.durationType == 'OPEN') return 'Until Lap Press';
    return `${step.durationValue}${getDurationValueType(
      step.durationValueType
    )}`;
  };

  const getRepeatValue = step => step.repeatValue;

  const getRepeatValueCell = workoutSteps => {
    const noWarmupNorCooldownWorkouts = workoutSteps?.filter(
      step => step.intensity != 'WARMUP' && step.intensity != 'COOLDOWN'
    );
    const repeatValues = noWarmupNorCooldownWorkouts?.map((step, index) => {
      if (step.type === 'WorkoutRepeatStep') return getRepeatValue(step);
      return '-';
    });
    if (repeatValues?.length)
      return repeatValues?.reduce((prev, curr) => [
        prev,
        <p key={Math.random()} style={{ fontSize: 'inherit', marginBottom: 0 }}>
          {curr}
        </p>
      ]);
    return '-';
  };

  const getIntenseDurationValueCell = workoutSteps => {
    const noWarmupNorCooldownWorkouts = workoutSteps?.filter(
      step => step.intensity != 'WARMUP' && step.intensity != 'COOLDOWN'
    );
    const durationValues = noWarmupNorCooldownWorkouts?.map((step, index) => {
      if (step.type === 'WorkoutStep') return getStepDuration(step);
      if (step.type === 'WorkoutRepeatStep')
        return getStepDuration(step.steps[0]);
      return '-';
    });
    if (durationValues?.length)
      return durationValues?.reduce((prev, curr) => [
        prev,
        <p key={Math.random()} style={{ fontSize: 'inherit', marginBottom: 0 }}>
          {curr}
        </p>
      ]);
    return '-';
  };

  const getRecoveryDurationValueCell = workoutSteps => {
    const noWarmupNorCooldownWorkouts = workoutSteps?.filter(
      step => step.intensity != 'WARMUP' && step.intensity != 'COOLDOWN'
    );
    const durationValues = noWarmupNorCooldownWorkouts?.map((step, index) => {
      if (step.type === 'WorkoutRepeatStep')
        return getStepDuration(step.steps[1]);
      return '-';
    });
    if (durationValues?.length)
      return durationValues?.reduce((prev, curr) => [
        prev,
        <p key={Math.random()} style={{ fontSize: 'inherit', marginBottom: 0 }}>
          {curr}
        </p>
      ]);
    return '-';
  };

  const getRunnerStepPace = step => {
    if (step.runnerPace) return convertSecondsToPace(step.runnerPace);

    const type = step.targetType
    if (!type) return 'EASY'
    if (type === 'PACE') return (
      isNaN(step.targetValue)?step.targetValue:
        (step.targetValue==0)?'BASE':
          (step.targetValue>0)?`+${step.targetValue}s`:`${step.targetValue}s`
    )
    if (step.targetType === 'HEART_RATE') return HRZones[step.targetValue]
    return 'EASY'
  };

  const getIntensePaceValueCell = workoutSteps => {
    const noWarmupNorCooldownWorkouts = workoutSteps?.filter(
      step => step.intensity != 'WARMUP' && step.intensity != 'COOLDOWN'
    );
    const paceValues = noWarmupNorCooldownWorkouts?.map((step, index) => {
      if (step.type === 'WorkoutStep') return getRunnerStepPace(step);
      if (step.type === 'WorkoutRepeatStep')
        return getRunnerStepPace(step.steps[0]);
      return '-';
    });
    if (paceValues?.length)
      return paceValues?.reduce((prev, curr) => [
        prev,
        <p key={Math.random()} style={{ fontSize: 'inherit', marginBottom: 0 }}>
          {curr}
        </p>
      ]);
    return '-';
  };

  const getRecoveryPaceValueCell = workoutSteps => {
    const noWarmupNorCooldownWorkouts = workoutSteps?.filter(
      step => step.intensity != 'WARMUP' && step.intensity != 'COOLDOWN'
    );
    const paceValues = noWarmupNorCooldownWorkouts?.map((step, index) => {
      if (step.type === 'WorkoutRepeatStep')
        return getRunnerStepPace(step.steps[1]);
      return '-';
    });
    if (paceValues?.length)
      return paceValues?.reduce((prev, curr) => [
        prev,
        <p key={Math.random()} style={{ fontSize: 'inherit', marginBottom: 0 }}>
          {curr}
        </p>
      ]);
    return '-';
  };

  return (
    <>
      <CellComponent
        align="center"
        data-test={'desktopWeeklyPlanWorkoutLine-mainPart-repeatValueCell'}>
        <Paper className={classes.transparentPaperBox}>
          {getRepeatValueCell(workoutSteps)}
        </Paper>
      </CellComponent>
      <CellComponent align="center">
        <div className={classes.greyPaperbox}>
          <Paper
            className={classes.transparentPaperBox}
            data-test={
              'desktopWeeklyPlanWorkoutLine-mainPart-intenseDurationValueCell'
            }>
            {getIntenseDurationValueCell(workoutSteps)}
          </Paper>
          <Paper
            className={classes.transparentPaperBox}
            data-test={
              'desktopWeeklyPlanWorkoutLine-mainPart-intensePaceValueCell'
            }>
            {getIntensePaceValueCell(workoutSteps)}
          </Paper>
        </div>
      </CellComponent>
      <CellComponent align="center">
        <div className={classes.greyPaperbox}>
          <Paper
            className={classes.transparentPaperBox}
            data-test={
              'desktopWeeklyPlanWorkoutLine-mainPart-recoveryDurationValueCell'
            }>
            {getRecoveryDurationValueCell(workoutSteps)}
          </Paper>
          <Paper
            className={classes.transparentPaperBox}
            data-test={
              'desktopWeeklyPlanWorkoutLine-mainPart-recoveryPaceValueCell'
            }>
            {getRecoveryPaceValueCell(workoutSteps)}
          </Paper>
        </div>
      </CellComponent>
    </>
  );
  
}




export default function DesktopWeeklyPlanWorkoutLine(props) {
  const {
    user,
    type,
    updateWeekView,
    handleWatchSyncIconClick,
    workoutData,
    updateWorkouts
  } = props;

  const classes = useStyles();
  const [workout, setWorkout] = useState(workoutData);
  const [workoutInfo, setWorkoutInfo] = useState(workout.workoutTemplate || workout.workout);
  const [workoutName, setWorkoutName] = useState(
    workoutInfo ? workoutInfo?.workoutName : ''
  );
  const [workoutSteps, setWorkoutSteps] = useState(
    workoutInfo ? workoutInfo?.steps : ''
  );
  const [isHoverTrainerToEdit, setIsHoverTrainerToEdit] = useState(false);
  const [isHoverRunnerToSync, setIsHoverRunnerToSync] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  // const tooltipElementRef = useRef(null)
  // const rowRef = useRef(null)
  
  const maxNoteCharCount = 30

  const handleMouseEnter = () => {
    if (workoutInfo && (workoutInfo.workoutNotes.length > maxNoteCharCount)) setShowTooltip(true)
    if (user.role == 'trainer') setIsHoverTrainerToEdit(true);
    if (user.role == 'runner') setIsHoverRunnerToSync(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false)
    if (isHoverTrainerToEdit) setIsHoverTrainerToEdit(false);
    if (isHoverRunnerToSync) setIsHoverRunnerToSync(false);
  };


  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const handleModalOpen = () => {
    if (user.role == 'trainer') {
      setShowTooltip(false)
      setIsModalOpen(true)
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const getRowHoverStyle = () => {
    if (isHoverTrainerToEdit) return classes.rowHoverTrainerToEditStyle;
    if (isHoverRunnerToSync && user.garminUserId)
      return classes.rowHoverRunnerToSyncStyle;
    return classes.tblRow;
  };
  // const [tooltip, setTooltip] = useState()
  // const mountTooltip = element => {
  //   setTooltip(tippy(element, {
  //     content: workoutInfo.workoutNotes,
  //     allowHTML: true,
  //     maxWidth: 400,
  //     theme: 'light',
  //   }));
  // };

  // useEffect(() => {
  //   if (workoutInfo && tooltipElementRef.current) {
      
  //     if (tooltip) {
  //       workoutInfo.workoutNotes.trim() ? tooltip.setProps({ content: workoutInfo.workoutNotes }) : tooltip.destroy(); setTooltip(null)
  //     } else if (workoutInfo.workoutNotes) {
  //       mountTooltip(tooltipElementRef.current)
  //     }
  //   }
  // }, [workoutInfo, tooltipElementRef]);
  useEffect(() => {
    const newInfo = workout.workoutTemplate || workout.workout
    setWorkoutInfo(newInfo);
    if (newInfo) {
      setWorkoutName(newInfo ? newInfo?.workoutName : '');
      setWorkoutSteps(newInfo ? newInfo?.steps : '');
    }
  }, [workout])

  return (
    <>
      <TableRow
        className={getRowHoverStyle()}
        //ref={rowRef}
        data-test={weekdays[new Date(workout.dateObject).getDay()]}
        onClick={() => {
          handleModalOpen();
        }}
        
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        <StyledTableCell className={classes.tblHeadingLeft} component="th">
          <p style={{ margin: 0 }}>
            {weekdays[new Date(workout.dateObject).getDay()]}
            <br />
            {`${new Date(workout.dateObject).getDate()}/${new Date(
              workout.dateObject
            ).getMonth() + 1}`}
          </p>
        </StyledTableCell>
        {/* {workout && workout.workoutTemplate ? ( */}
        {workoutInfo ? (
          <>
            <LightTooltip
              PopperProps={{
                popperOptions: {
                  modifiers: {
                    flip: { enabled: false },  
                  }
                }
              }} 
              open={showTooltip} placement='bottom-start'
              title={workoutInfo && workoutInfo.workoutNotes.trim()}>
              <StyledTableCell
                //ref={tooltipElementRef}
                align="center"
                className={classes.roundStartCorners}>
                <strong data-test={'desktopWeeklyPlanWorkoutLine-workoutName'}>
                  {workoutName}
                </strong>
                <span style={{ fontSize: '12px', textAlign:'center', textWrap: 'nowrap', display: 'block' }}>
                  {(workoutInfo.workoutNotes.length > maxNoteCharCount) ? `${workoutInfo.workoutNotes.substring(0, maxNoteCharCount)}...`: workoutInfo.workoutNotes}
                </span>
              </StyledTableCell>
            </LightTooltip>

            <StyledTableCell
              align="center"
              data-test={'desktopWeeklyPlanWorkoutLine-warmUpDuration'}>
              {/* {getWarmupDuration(workout.workoutTemplate.steps)} */}
              {getWarmupDuration(workoutSteps)}
            </StyledTableCell>
            {/* <MainPart workoutSteps={workout.workoutTemplate.steps} /> */}
            
            
            <MainPart workoutSteps={workoutSteps} />


            <StyledTableCell
              align="center"
              className={classes.roundEndCorners}
              data-test={'desktopWeeklyPlanWorkoutLine-coolDownDuration'}>
              {/* {getCooldownDuration(workout.workoutTemplate.steps)} */}
              {getCooldownDuration(workoutSteps)}
            </StyledTableCell>
            {isHoverRunnerToSync && user.garminUserId && (
              <StyledTableCell
                className={classes.hoverRunnerToSync}
                onClick={() => handleWatchSyncIconClick(workoutData)}>
                <WatchSyncIcon color={'5157F8'} size={30} /> Sync workout to
                watch
              </StyledTableCell>
            )}
          </>
        ) : null}
      </TableRow>

      {/* An empty row for separation */}
      <TableRow className={classes.tblRow}>
        <TableCell colSpan={6} style={{ border: 0 }}></TableCell>
      </TableRow>
      <WorkoutChangeModal
        handleModalClose={handleModalClose}
        isModalOpen={isModalOpen}
        open={isModalOpen}
        setWorkout={setWorkout}
        setWorkoutName={setWorkoutName}
        setWorkoutSteps={setWorkoutSteps}
        type={type}
        updateWorkouts={updateWorkouts}
        user={user}
        updateWeekView={updateWeekView}
        // workout={{ ...workout, trainerUserId: user._id }}
        workout={workout}
      />
    </>
  );
}