import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Link,
  Snackbar,
  TextField,
  Typography
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import validate from 'validate.js';
import useStyles from './styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

import {
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword
} from 'firebase/auth';
import { initiatedFirebaseApp } from '../../config/firebase-config';
let auth;
if (initiatedFirebaseApp) {
  auth = getAuth();
}

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  }
};

const getUserDataFromDB = async userEmail => {
  if (!userEmail) return null;
  const userDataFromDB = await axios.post(
    `${process.env.REACT_APP_SERVER_URL}/isUser`,
    { userEmail }
  );
  return userDataFromDB.data;
};

const SignIn = props => {
  const { history, setUser } = props;
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [userEmail, setUserEmail] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const [severity, setSeverity] = useState('error');
  const [firebaseMessage, setFirebaseMessage] = useState();
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
    setTimeout(() => {
      setSeverity(null);
    }, 1000);
  };

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    const { name, value } = event.target;

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [name]: value
      },
      touched: {
        ...formState.touched,
        [name]: true
      }
    }));

    // Set userEmail here when the email input changes
    if (name === 'email') {
      setUserEmail(value);
    }
  };

  const handleSignIn = async event => {
    event.preventDefault();
    try {
      const response = await signInWithEmailAndPassword(
        auth,
        formState.values.email,
        formState.values.password
      );
      if (response) {
        getUserDataFromDB(formState.values.email).then(res =>
          setUser({ ...response.user, ...res })
        );
        history.push('/dashboard');
      }
    } catch (error) {
      if (
        error.code === 'auth/wrong-password' ||
        error.code === 'auth/user-not-found' ||
        error.code === 'auth/invalid-login-credentials'
      ) {
        const message = 'Wrong username or password. Please try again...';
        setFirebaseMessage(message);
        setOpen(true);
      }
      if (error.code === 'auth/too-many-requests') {
        const message = 'Too many requests. Please try again later...';
        setFirebaseMessage(message);
        setOpen(true);
      } else {
        console.log(error.code);
      }
    }
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const handleForgetPassword = async () => {
    let message = '';
    event.preventDefault();
    if (hasError('email')) {
      message = formState.errors.email
        ? formState.errors.email[0]
        : 'Please Enter Your email First!';
      console.log(message);
      setFirebaseMessage(message);
      setOpen(true);
    } else if (!userEmail || !formState.values.email) {
      message = 'Please Enter Your Email First!';
      console.log(message);
      setFirebaseMessage(message);
      setOpen(true);
    } else {
      setOpenDialog(true);
    }
  };

  const resetPassword = async () => {
    event.preventDefault();
    let message = '';
    setOpenDialog(false);
    sendPasswordResetEmail(auth, userEmail)
      .then(() => {
        message = 'Please Check Your Email!';
        console.log(message);
        setSeverity('info');
        setFirebaseMessage(message);
        setOpen(true);
      })
      .catch(error => {
        if (error.code === 'auth/user-not-found') {
          message = 'User Not Found!';
          console.log(message);

          setFirebaseMessage(message);
          setOpen(true);
        }

        if (error.code === 'auth/too-many-requests') {
          message = 'Too many requests. Please try again later...';
          console.log(message);
          setFirebaseMessage(message);
          setOpen(true);
        } else {
          console.log(error.code);
        }
      });
  };

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.content} item lg={7} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <Typography className={classes.headerText} variant="h1">
                Sub-X
              </Typography>
              <Typography className={classes.headerText} variant="h3">
                Welcome to Sub-X
              </Typography>
              <Typography className={classes.title} variant="h3">
                Login
              </Typography>
            </div>
            <div className={classes.contentBody}>
              <form className={classes.form} onSubmit={handleSignIn}>
                <Box className={classes.formHeader}>
                  <TextField
                    className={classes.textField}
                    error={hasError('email')}
                    fullWidth
                    helperText={
                      hasError('email') ? formState.errors.email[0] : null
                    }
                    label="Email"
                    name="email"
                    onChange={handleChange}
                    type="text"
                    value={formState.values.email || ''}
                    variant="outlined"
                  />
                  <TextField
                    className={classes.textField}
                    error={hasError('password')}
                    fullWidth
                    helperText={
                      hasError('password') ? formState.errors.password[0] : null
                    }
                    label="Password"
                    name="password"
                    onChange={handleChange}
                    type="password"
                    value={formState.values.password || ''}
                    variant="outlined"
                  />
                  <Box className={classes.linkWrapper}>
                    {/* need to add endpoint to the link*/}

                    <Link
                      component="button"
                      onClick={event => handleForgetPassword(event)}
                      variant="body2">
                      <Typography
                        className={classes.forgotPwdLink}
                        variant="body1">
                        Forgot Password?
                      </Typography>
                    </Link>
                  </Box>
                </Box>
                <Box className={classes.formFooter}>
                  <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!formState.isValid}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained">
                    Login
                  </Button>
                  <Typography color="textSecondary" variant="body1">
                    Don't have an account?{' '}
                    <Link component={RouterLink} to="/sign-up" variant="h5">
                      Sign up
                    </Link>
                  </Typography>
                </Box>
              </form>
            </div>
          </div>
        </Grid>
        <Grid className={classes.imgContainer} item lg={5}>
          <div className={classes.img} />
        </Grid>
      </Grid>

      {/* ------------------- Snack Bar ---------------------- */}

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        autoHideDuration={4000}
        onClose={handleClose}
        open={open}>
        <Alert
          data-testid={severity || 'error'}
          onClose={handleClose}
          severity={severity || 'error'}>
          {firebaseMessage}
        </Alert>
      </Snackbar>
      {/* ------------------- Dialog ---------------------- */}

      <Dialog
        data-test="signIn-forgetPasswordConfimationDialog"
        onClose={() => setOpenDialog(false)}
        open={openDialog}>
        <DialogTitle>Are you sure you want to reset password?</DialogTitle>

        <DialogActions style={{ justifyContent: 'center' }}>
          <Button
            color="primary"
            data-test="signIn-forgetPasswordConfimationDialog-yesButton"
            onClick={event => resetPassword(event)}
            variant="contained">
            Yes
          </Button>
          <Button
            color="primary"
            data-test="signIn-forgetPasswordConfimationDialog-noButton"
            onClick={event => {
              event.preventDefault();
              setOpenDialog(false);
            }}
            variant="outlined">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

SignIn.propTypes = {
  history: PropTypes.object
};

export default withRouter(SignIn);
