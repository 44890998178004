import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import AccountDetails from './Row/AccountDetails';

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // height: '100%',
    padding: theme.spacing(4)
  }
}));

const RunnerSettingsModal = props => {
  const {
    isModalOpen,
    handleModalClose,
    user,
    runner,
    setRunner,
    refresh
  } = props;
  const classes = useStyles();
  // open is required for the MUI V4 modal component
  const open = isModalOpen;

  return (
    <Modal
      open={isModalOpen}
      onClose={handleModalClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description">
      <div className={classes.paper}>
        <AccountDetails
          handleModalClose={handleModalClose}
          user={user}
          refresh={refresh}
          runner={runner}
          setRunner={setRunner}
        />
      </div>
    </Modal>
  );
};

export default RunnerSettingsModal;
