/* eslint-disable react/no-multi-comp */
import React, { useState, useEffect, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import {
  Box,
  CircularProgress,
  Grow,
  Paper,
  makeStyles,
  useMediaQuery
} from '@material-ui/core';
import axios from 'axios';
import interactionPlugin from '@fullcalendar/interaction';
import { SnackbarNotification, SubGroup } from 'components';
import WorkoutTemplateSelection from '../WorkoutTemplateSelection';
import tippy from 'tippy.js';
import 'tippy.js/themes/light.css';
import { DeleteOutline } from '@material-ui/icons';
const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    position: 'absolute',
    top: 10,
    right: 10
  },
  scrollbarBox: {
    minWidth: '250px',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    overflow: 'hidden auto',
    '&::-webkit-scrollbar': {
      width: '0.4em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      borderRadius: '2px'
    }
  }
}));

function MonthView({ user, setWorkoutSelectedByMonthView, setValue }) {
  const [padding, setPadding] = useState(32);

  const [selectedSubgroup, setSelectedSubgroup] = useState('General');
  const [calendarApi, setCalendarApi] = useState();
  const [subgroups, setSubgroups] = useState(['General']);

  const [events, setEvents] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const classes = useStyles();

  const RootBox = useRef(null);
  const Calendar = useRef(null);

  const isMobile = !useMediaQuery('(min-width:900px)');
  const [isNotification, setIsNotification] = React.useState(false);
  const [notificationSeverity, setNotificationSeverity] = React.useState('');
  const [notificationMessage, setNotificationMessage] = React.useState('');

  const [hoveringDelete, setHoveringDelete] = React.useState(false);
  const [isDragging, setIsDragging] = React.useState(false);

  const displayNotification = (severity, message) => {
    setNotificationSeverity(severity);
    setNotificationMessage(message);
    setIsNotification(true);
  };

  const onResize = () => {
    if (!RootBox.current) return;
    if (RootBox.current.offsetWidth < 800) setPadding(0);
    else setPadding(4);
    Calendar.current.getApi().render(); // force render to update to new padding
  };

  const initilizeCalendar = async () => {
    fetchData();
  };

  const fetchData = async fetchDate => {
    setLoading(true);
    if (!calendarApi) return;
    const activeRange =
      calendarApi.currentDataManager.state.dateProfile.activeRange;
    const workoutPlanResponse = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/workout/getWorkoutPlans`,
      {
        userId: user._id,
        range: { from: activeRange.start, to: activeRange.end }
      }
    );
    const data = workoutPlanResponse.data;
    setLoading(false);
    setData(data);
  };
  const getFormattedDate = d => {
    d = new Date(d);
    const day = d.getDate();
    const month = d.getMonth() + 1; // Month is 0-based
    const dayNumber = d.getDay();
    const days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ];
    const dayOfWeek = days[dayNumber];
    return {
      dayOfWeek,
      formattedDate: `${day}/${month}`
    };
  };
  const updateCalendar = () => {
    // filter workouts and format to work with calendar
    let filteredWorkouts = []; // filtered and parsed workouts variable
    for (let i = 0; i < data.length; i++) {
      const workoutPlan = data[i];
      if (workoutPlan.subGroup != selectedSubgroup) continue;
      const { dayOfWeek, formattedDate } = getFormattedDate(
        workoutPlan.dateObject
      );
      filteredWorkouts.push({
        id: workoutPlan._id,
        title: workoutPlan.workoutTemplate.workoutName,
        start: new Date(workoutPlan.dateObject),
        allDay: true,
        extendedProps: {
          workoutPlan: {
            isWorkout: true,
            dateObject: new Date(workoutPlan.dateObject),
            dayOfWeek: dayOfWeek,
            date: formattedDate,
            isActive: false,
            subGroup: selectedSubgroup,
            workoutTemplate: { ...workoutPlan.workoutTemplate }
          }
        }
      });
    }
    setEvents(filteredWorkouts);
  };
  function sameDay(date1, date2) {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  const handleEventClick = info => {
    setWorkoutSelectedByMonthView(info.event.extendedProps.workoutPlan);
    setValue(0);
  };
  const handleDateClick = info => {
    const date = new Date(info.date);

    for (let i in events) {
      const event = events[i];
      if (sameDay(event.start, date)) {
        setWorkoutSelectedByMonthView(event.extendedProps.workoutPlan);
        setValue(0);
        return;
      }
    }
    const { dayOfWeek, formattedDate } = getFormattedDate(date);
    const workoutPlan = {
      isWorkout: false,
      dateObject: date,
      dayOfWeek: dayOfWeek,
      date: formattedDate,
      isActive: false,
      subGroup: selectedSubgroup,
      workoutTemplate: null
    };
    setWorkoutSelectedByMonthView(workoutPlan);
    setValue(0);
  };
  const handleDrop = async info => {
    const template = {
      userEmail: user.email,
      dateObject: info.event.start,
      subGroup: selectedSubgroup,
      workoutTemplate: info.event.extendedProps.template
    };
    template.workoutTemplate.userEmail = user.email;
    template.workoutTemplate.subGroup = selectedSubgroup;

    delete template.workoutTemplate._id;
    delete template.workoutTemplate.trainerUserId;

    for (let i = 0; i < events.length; i++) {
      const event = events[i];
      if (sameDay(event.start, info.event.start)) {
        info.revert();
        displayNotification(
          'error',
          'there is already an existing workout template in the selected date'
        );
        return;
      }
    }
    //info.event.backgroundColor = '#1c3c5c'

    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/workout/toPlanTemplate`,
      template,
      {
        validateStatus: () => true
      }
    );
    if (response.status == 200) {
      info.revert();
      const { dayOfWeek, formattedDate } = getFormattedDate(
        template.dateObject
      );
      const data = {
        id: Math.floor(Math.random()*100000000000),
        title: template.workoutTemplate.workoutName,
        start: new Date(template.dateObject),
        allDay: true,
        extendedProps: {
          workoutPlan: {
            isWorkout: true,
            dateObject: new Date(template.dateObject),
            dayOfWeek: dayOfWeek,
            date: formattedDate,
            isActive: false,
            subGroup: selectedSubgroup,
            workoutTemplate: { ...template.workoutTemplate }
          }
        }
      }
      setEvents([...events, data]);
      displayNotification(response.data.severity, response.data.msg);
    } else {
      displayNotification(response.data.severity, response.data.msg);
      info.revert();
    }
  };
  const handleSubgroupRename = () => {
    fetchData();
  };

  const handleDateChange = info => {
    fetchData();
  };
  // initial calendar setup
  //(async funtion so you have to call it in a none async funtion)
  useEffect(() => {
    setCalendarApi(Calendar.current.getApi());
  }, [Calendar]);

  // on calendar update
  // (this is for automatic updates when selected runner is changed)
  useEffect(updateCalendar, [data, selectedSubgroup]);

  useEffect(() => {
    initilizeCalendar();
  }, [calendarApi]);
  // resize listener
  useEffect(() => {
    window.addEventListener('resize', onResize);
    onResize();
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [RootBox, onResize]);

  const mountTooltip = info => {
    if (info.event.title.length > 20) {
      tippy(info.el, {
        theme: 'light',
        content: info.event.title
      });
    }
  };

  const handleDragStart = info => {
    setIsDragging(true);
    document.body.style.cursor = 'grabbing';
    info.el.classList.add('fc-event-invisible')
  };

  const handleDragStop = async info => {
    setIsDragging(false);
    document.body.style.cursor = 'default';
    
    //const workoutPlan = info.event.extendedProps.workoutPlan
    if (hoveringDelete) {
      for (let i = 0; i < events.length; i++) {
        const event = events[i];
        if (sameDay(event.start, info.event.start)) {
          setLoading(true);
          const response = await axios.post(
            `${process.env.REACT_APP_SERVER_URL}/workout/removeFromPlanTemplate`,
            { userEmail: user.email, activeDateObject: info.event.start },
            { validateStatus: () => true }
          );
          if (response.status == 200) {
            const filteredEvents = events.filter(event => event.id != info.event.id)
            setEvents(filteredEvents);
            displayNotification('success', 'workout template deleted successfully');
          } else if (response.status >= 400 && response.status <= 421) {
            displayNotification(response.data.severity, response.data.msg);
            info.el.classList.remove('fc-event-invisible')
          } else if (response.status >= 500) {
            displayNotification('error', 'there was an error deleting the workout template');
            info.el.classList.remove('fc-event-invisible')
          } else {
            displayNotification('error', 'there was an unknown error deleting the workout template');
            info.el.classList.remove('fc-event-invisible')
          }
          setLoading(false);
          return;
        }
      }
    } else {
      info.el.classList.remove('fc-event-invisible')
    } 
  }

  useEffect(() => {
    const element = document.querySelector('.fc-event-dragging');
    if (!element || element.classList.contains('draggable-card')) return;
    element.classList.toggle('fc-event-deleting')
  }, [hoveringDelete]);

  return (
    <Box
      alignItems={'stretch'}
      display={'flex'}
      gridGap={32}
      height={isMobile ? 'auto' : '716px'} // dont change this
      justifyContent={'center'}
      padding={padding}
      ref={RootBox}>
      <Paper style={{ maxWidth: '1000px', padding: padding ? padding * 8 : 1 }}>
        <Box position={'relative'}>
          <SubGroup
            onSubgroupRename={handleSubgroupRename}
            onSubgroupsChanged={subgroups => {
              setSubgroups(subgroups);
            }}
            selectedSubgroup={selectedSubgroup}
            setSelectedSubgroup={setSelectedSubgroup}
            style={{
              position: 'absolute',
              top: 10,
              right: 0
            }}
            user={user}
            width="175px"
          />
          <div
            onMouseLeave={()=>setHoveringDelete(true)}
            onMouseEnter={()=>setHoveringDelete(false)}
            className="clickable-days"
          >
            <FullCalendar
              contentHeight="auto"
              dateClick={handleDateClick}
              datesSet={handleDateChange}
              dragRevertDuration={0}
              droppable
              editable
              eventClick={handleEventClick}
              eventDidMount={mountTooltip}
              eventDisplay="block"
              eventDragStart={handleDragStart}
              eventDragStop={handleDragStop}
              eventDrop={(e)=>e.revert()}
              eventOverlap={false}
              eventReceive={handleDrop}
              events={events}
              firstDay={0} // 0 = sunday, 1 = monday...
              headerToolbar={{
                start: 'prev title next',
                end: ''
              }}
              initialView="dayGridMonth"
              plugins={[dayGridPlugin, interactionPlugin]}
              ref={Calendar}
            />
          </div>
          {loading && (
            <div className={classes.formControl} style={{ top: 0,left: 0 }}>
              <CircularProgress size={'1rem'} />
            </div>
          )}
          {/* <Grow in={isDragging} style={{ transformOrigin: '0 0 0' }}>
            <Paper
              onMouseEnter={()=>setHoveringDelete(true)}
              onMouseLeave={()=>setHoveringDelete(false)}
              style={{
                position: 'fixed',
                bottom: 32,
                right: 32,
                zIndex: 1000
              }}
            >
              <DeleteOutline style={{ fontSize: '3.5rem', transition: '.3s', color: hoveringDelete ? 'red' : 'black' }} />
            </Paper>
          </Grow> */}
        </Box>
      </Paper>
      {!isMobile && (
        <Box
          display={'flex'}
          flexDirection={'column'}
          gridGap={32}
        >
          {/* <Paper
          >
          </Paper> */}
          <Box className={classes.scrollbarBox}>
            <WorkoutTemplateSelection
              monthView
              selectedSubgroup={selectedSubgroup}
              subgroups={subgroups}
              user={user}
            />
          </Box>
        </Box>
      )}
      <SnackbarNotification
        isNotificationDisplayed={isNotification}
        message={notificationMessage}
        setIsNotificationDisplayed={setIsNotification}
        severity={notificationSeverity}
      />
    </Box>
  );
}

export default MonthView;
