import { Box, Container, Grid, Table, TableBody, TableContainer, TableRow, styled } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import TableHeading from './TemplateTableHeading'
import TemplateTableRow from './TemplateTableRow'
import axios from 'api/axios'
import { ArrowForwardIosRounded } from '@material-ui/icons'



const StyledTable = styled(Table)({
  borderCollapse: 'separate',
  borderSpacing: '0 10px'
})
const MobileRow = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '15px 10px',
  background: '#f5f5f5',
  borderRadius: '10px',
  boxShadow: '0 2px 4px 0 rgba(0,0,0, .2)',
  fontWeight: 600,
  fontSize: '1.1rem',
  color: '#3a46a7'
})
const TemplateTable = ({user, subgroup, setLoading, setSelectedTemplate, mobile=false}) => {
  const [templates, setTemplates] = useState()
  const getTrainerTemplates = async () => {
    setLoading(true)
    const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/workout/templatesFromDB`, { ...user })
    const templatesData = res.data.workoutTemplates
    const filteredTemplates = templatesData.filter(template => template.subGroup === subgroup)
    setTemplates(filteredTemplates)
    setLoading(false)
  }
  
  useEffect(() => {
    getTrainerTemplates()
  }, [subgroup])


  return (
    <>
      {!mobile ?  
        // DESKTOP VIEW
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TableContainer>
              <StyledTable>
                <TableHeading/>
                <TableBody>
                  {templates && templates.map(template => (
                    <TemplateTableRow key={template._id} template={template} setSelectedTemplate={setSelectedTemplate}/>
                  ))}
                </TableBody>
              </StyledTable>
            </TableContainer>
          </Grid>
        </Grid>: 
        // MOBILE VIEW
        <Box display={'flex'} flexDirection={'column'} justifyContent={'stretch'} gridGap={12} pt={1}>
          {templates && templates.map(template => (
            <MobileRow key={template._id} onClick={() => setSelectedTemplate(template)}>
              {template.workoutName}
              <ArrowForwardIosRounded style={{ color: 'grey' }} />
            </MobileRow>
          ))}
        </Box>
      }
    </>
)
}

export default TemplateTable