import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import DatePicker from '../DatePicker/DatePicker';
import SearchInput from '../../../../components/SearchInput';

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    justifyContent: 'space-between'
  }
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { setDate } = props;

  return (
    <Toolbar className={clsx(classes.root)}>
      <DatePicker setDate={setDate} />
      <SearchInput />
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired
};

export default EnhancedTableToolbar;
