import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Paper, Tab, Tabs } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import CreateWorkout from './components/CreateWorkout';
import MonthView from './components/MonthView/MonthView';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2)
  }
}));

const CreateWorkoutPlan = props => {
  const { user } = props;
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [workoutSelectedByMonthView, setWorkoutSelectedByMonthView] = useState()

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (<>
    <Paper square>
      <Tabs
        indicatorColor="primary"
        onChange={handleChange}
        textColor="primary"
        value={value}>
        <Tab label="Weekly View" />
        <Tab label="Monthly View" />
      </Tabs>
    </Paper>
      {value ? <Grid container justifyContent="center"><MonthView user={user} setValue={setValue} setWorkoutSelectedByMonthView={setWorkoutSelectedByMonthView}/></Grid> : 
        <Paper className={classes.root} square>
          <CssBaseline />
          <CreateWorkout user={user} workoutSelectedByMonthView={workoutSelectedByMonthView} />
        </Paper>
      }
    </>
  );
};

export default CreateWorkoutPlan;
